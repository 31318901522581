var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cello_enabled
    ? _c("div", { staticClass: "t-nav__referral" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "t-nav__referral_trigger" }, [
      _c("img", {
        staticClass: "t-nav__referral-icon",
        attrs: { src: require("@/lib/assets/images/cello.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }