var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.partnerMode || _vm.spinner
    ? _c("div", [_vm._m(0)])
    : _c(
        "div",
        {
          staticClass: "t-img-fill",
          class: _vm.size && `t-img-fill--${_vm.size}`,
        },
        [
          _c("img", {
            staticClass: "t-img-el",
            attrs: {
              src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/Trainn-logo-mono.svg"),
              height: "100%",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "t-img-el",
            attrs: {
              src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/Trainn-logo-red.svg"),
              height: "100%",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "t-img-el",
            attrs: {
              src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/Trainn-logo-blue.svg"),
              height: "100%",
            },
          }),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "lds-ring" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }