var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    !_vm.search
      ? _c("div", { key: "header", staticClass: "t-header" }, [
          _c(
            "div",
            { staticClass: "t-header__main" },
            [
              _c("portal-target", {
                staticClass: "t-header__slot t-mar--r-md",
                attrs: { name: "header-main" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "t-header__aside" },
            [
              _c("portal-target", {
                staticClass: "t-header__actions",
                attrs: { name: "header-actions" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }