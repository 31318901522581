export default {
  title: 'Enter title',
  untitled: 'Untitled article',
  article_title: 'Article title',
  publish: 'Publish Article',
  upvotes: 'Total Upvotes',
  downvotes: 'Total Downvotes',
  delete: {
    title: 'Are you sure you want to delete this article?',
    description:
      'This article will be deleted forever and any links associated with this article will not be available anymore.'
  }
};
