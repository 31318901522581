class Logger {
  error(msg: any) {
    console.error(msg)
  }

  info(msg: any) {
    console.log(msg)
  }

  debug(msg: any) {
    console.debug(msg)
  }

  warn(msg: any) {
    console.warn(msg)
  }
}

export default new Logger()