var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "t-message", class: _vm.size && `t-message--${_vm.size}` },
    [
      _c("div", { staticClass: "t-message__container", class: _vm.styles }, [
        _vm.icon
          ? _c("div", { staticClass: "t-message__icon" }, [
              _vm.type == "success"
                ? _c("i", { staticClass: "fas fa-check-circle fa-fw" })
                : _vm.type == "error"
                ? _c("i", { staticClass: "fas fa-exclamation-circle fa-fw" })
                : _c("i", { staticClass: "fas fa-info-circle fa-fw" }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "t-message__content" },
          [_vm.message ? [_vm._v(_vm._s(_vm.message))] : _vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }