
declare let CELLO_PRODUCT_ID: string;
declare let CELLO_ENABLED: string;
import Vue from 'vue';
import Component from 'vue-class-component';
import apiClient from "@/app/clients/api-client";

@Component({
  name: 'cello-referral-component',
})
export default class CelloReferralComponent extends Vue {
  private cello_enabled = CELLO_ENABLED
  mounted() {
    if (this.cello_enabled && this.$user) {
      apiClient
          .celloToken().then((data) => {
        if (this.$user) {
          const token = data.token;
          window.cello = window.cello || {cmd: []};
          window.cello.cmd.push(function (cello) {
            cello.boot({
              productId: CELLO_PRODUCT_ID,
              token: token,
              language: "en",
              customLauncherSelector: '.t-nav__referral_trigger',
              hideDefaultLauncher: true,
              onTokenExpiring: () => {
                try {
                  apiClient
                      .celloToken().then((data) => {
                    window.cello("updateToken", data.token);
                  });
                } catch (error) {
                  console.log('error, updating token');
                }
              }
            });
          })
        }
      }).catch((error) => {
        this.$error(error)
      });
    }
  }
}
