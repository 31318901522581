
import Vue from 'vue';
import Component from 'vue-class-component';
import UploadVideoComponent from "@/app/components/UploadVideoComponent.vue";

@Component({
  name: 'upload-video-modal',
  components: {
    UploadVideoComponent
  }
})
export default class UploadVideoModal extends Vue {
  uploadVersion = false;
  
  handleBeforeOpen(e) {
    this.uploadVersion = e.params && e.params.uploadVersion;
  }
}
