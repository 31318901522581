var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in" } },
    [
      _vm.loaded
        ? [
            _vm.isLoadingRoute || _vm.isFullPage
              ? _c("router-view")
              : _vm.isLoggedInUser && !_vm.onBoarding
              ? _c(
                  "div",
                  {
                    key: "layout-1",
                    staticClass: "t-wrapper",
                    class: {
                      [`t-wrapper-${_vm.$route.name}`]: _vm.$route.name,
                    },
                  },
                  [
                    !_vm.ignoreNav
                      ? _c(
                          "div",
                          {
                            staticClass: "t-wrapper__nav",
                            class: {
                              "t-wrapper__nav--collapsed": _vm.$isMobile,
                            },
                          },
                          [_c("Nav", { attrs: { message: _vm.message } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "t-wrapper__body",
                        attrs: { id: "page-body" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "t-wrapper__header" },
                          [
                            _vm.canShowLimitExceeded
                              ? _c("div", {
                                  staticClass: "t-banner",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.bannerText),
                                  },
                                })
                              : _c("portal-target", {
                                  staticClass: "t-banner",
                                  attrs: { name: "banner" },
                                }),
                            _vm._v(" "),
                            _c("Header"),
                            _vm._v(" "),
                            _c("portal-target", {
                              attrs: { name: "banner-actions" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("router-view", {
                          key:
                            !_vm.$route.meta.keepalive &&
                            _vm.$route.name +
                              (_vm.$route.params.videoId ||
                                _vm.$route.params.collectionId ||
                                _vm.$route.params.pageName ||
                                this.$route.params.instanceId ||
                                ""),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    [
                      _vm.workspace.name == "intercom-demo-account"
                        ? _c("IntercomWidget")
                        : _c("FreshdeskWidget"),
                    ],
                    _vm._v(" "),
                    _c("create-video-modal"),
                    _vm._v(" "),
                    _c("UploadVideoModal"),
                    _vm._v(" "),
                    _c("ShowVideoModal"),
                    _vm._v(" "),
                    _c("create-folder-modal"),
                  ],
                  2
                )
              : _c(
                  "div",
                  { key: "layout-2", staticClass: "t-onboard" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }