<template>
  <!-- eslint-disable vue/valid-template-root -->
  <transition
    v-if="!isList"
    mode="out-in"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
    @leave="leave"
    @before-leave="beforeLeave"
  >
    <slot></slot>
  </transition>
  
  <transition-group
    v-else
    mode="out-in"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
    @leave="leave"
    @before-leave="beforeLeave"
  >
    <slot></slot>
  </transition-group>
</template>
<script type="text/javascript">
export default {
  props: ['isList', 'preventAnimation'],
  data() {
    return {
      elWidth: 0,
      timing: 0
    };
  },
  methods: {
    beforeEnter(el) {
      if (this.preventAnimation) {
        return;
      }
      el.style.opacity = 0;
    },
    // ------------------------------------------------------------------------------
    //  Animate div from 0px to its width
    // ------------------------------------------------------------------------------
    enter(el) {
      if (this.preventAnimation) {
        return;
      }
      const elWidthTemp = el.clientWidth;
      this.elWidth = elWidthTemp;
      this.timing = ((elWidthTemp / 10) * 1) / 100;
      el.style['width'] = '0';
      el.style.transition =
        'opacity ' + this.timing + 's, width ' + this.timing + 's ';
      window.setTimeout(() => {
        el.style['width'] = elWidthTemp + 'px';
      });
    },
    // ------------------------------------------------------------------------------
    //  Reset values
    // ------------------------------------------------------------------------------
    afterEnter(el) {
      if (this.preventAnimation) {
        return;
      }
      el.style.opacity = 1;
      window.setTimeout(() => {
        el.style.width = 'auto';
      }, this.timing);
    },
    beforeLeave(el) {
      if (this.preventAnimation) {
        return;
      }
      el.style.opacity = 0;
      const h = el.clientWidth;
      this.timing = ((h / 10) * 1) / 100;
      el.style.transition =
        'opacity ' +
        this.timing +
        's, width ' +
        this.timing +
        's ' +
        this.timing / 2 +
        's';
    },
    leave(el) {
      if (this.preventAnimation) {
        return;
      }
      el.style.width = el.clientWidth + 'px';
      window.setTimeout(() => {
        el.style.width = '0';
      });
    },
    // ------------------------------------------------------------------------------
    //  Reset values
    // ------------------------------------------------------------------------------
    afterLeave(el) {
      if (this.preventAnimation) {
        return;
      }
      window.setTimeout(() => {
        el.style.width = 'auto';
        el.style.opacity = 0;
      });
    }
  }
};
</script>
