import Vue from 'vue';
import apiClient from '@/app/clients/api-client';
import * as Sentry from '@sentry/browser';
import { ToastType } from '@/lib/components/toast/types';
import WorkspaceUtils from '@/app/utils/workspace-utils';

export interface WorkspaceSetting {
  name: string;
  displayName?: string;
  logo?: string;
  color?: string;
  configs?: any;
  primaryLanguage?: string;
  rootFolderId?: string;
  guideBackgroundColor?: string;
  guideButtonColor?: string;
  guideTextColor?: string;
  guideSpotlightColor?: string;
}

export default class UserController {
  state!: any;
  constructor() {
    this.state = Vue.observable({
      workspace: {},
      hubSetting: {},
      integrations: {},
      profile: {},
      onboarded: false,
      plans: [],
      addons: [],
      billingConfig: {},
      subscription: {},
      permissions: [],
      onBoardingTasks: {},
      engagementToken: undefined,
      learnerHash: undefined,
    });
  }

  load() {
    this.fetchSpaces();
    this.loadIntegrationSettings();
    this.fetchOnboardingTasks();
    return Promise.all([
      apiClient.getUserInfo().then((data) => {
        this.setOnboarded(data.onBoarded);
        this.setProfile(data);
        this.setActiveWorkspaceMemberships(data.activeWorkspaceMemberships);
        this.setPermissions(data.permissions);
        Sentry.setUser({ id: data.uuid });
        this.loadEngagementToken();
      }),
      this.loadWorkspaceInfo(),
    ]);
  }

  reload() {
    return apiClient.getUserInfo().then((data) => {
      this.setProfile(data);
    })
  }

  fetchSpaces() {
    return apiClient.getSpaces().then((data) => {
      Vue.set(this.state.workspace, 'spaces', data);
      const privateFolderId = data.find(
        (folder) => folder.spaceType === 'private_space'
      )?.id;
      const teamFolderId = data.find(
        (folder) => folder.spaceType === 'public_space'
      )?.id;
      Vue.set(this.state.workspace, 'privateFolderId', privateFolderId);
      Vue.set(this.state.workspace, 'teamFolderId', teamFolderId);
    });
  }

  loadEngagementToken() {
    apiClient.getEngagementToken().then((data) => {
      Vue.set(this.state, 'engagementToken', data.engagement_token);
      Vue.set(this.state, 'learnerHash', data.hash);
    });
  }

  loadWorkspaceInfo() {
    return apiClient.fetchWorkspaceInfo().then((data) => {
      if (!data || !data.workspaceSetting || !data.videoHubSetting) {
        return;
      }
      this.setWorkspace(data);
      this.setHubSetting(data);
    });
  }

  updatePrimaryLanguage(language) {
    return apiClient.updatePrimaryLanguage(language).then((data) => {
      Vue.set(this.state.workspace, 'primaryLanguage', data.primaryLang);
      Vue.set(this.state.workspace.configs, 'changePrimaryLang', false);
    });
  }

  updatePrimaryLanguageState(lang) {
    Vue.set(this.state.workspace, 'primaryLanguage', lang);
  }

  loadIntegrationSettings() {
    return apiClient.fetchIntegrationSettings().then((data) => {
      Vue.set(this.state.integrations, 'youtube', data.youtube);
      Vue.set(this.state.integrations, 'stripe', data.stripe);
      return data;
    });
  }

  fetchOnboardingTasks() {
    return apiClient
      .fetchOnboardingTasks()
      .then((data) => {
        this.syncOnboardingData(data);
      })
      .catch((error) => { });
  }

  syncOnboardingData(data) {
    const onBoardingTasks =
      data &&
      data.reduce((prev, next) => {
        prev[next.name] = next;
        if (prev && prev[next.name] && prev[next.name].children) {
          prev[next.name].children = prev[next.name].children.reduce((x, y) => {
            x[y.name] = y;
            return x;
          }, {});
        }
        return prev;
      }, {});
    Vue.set(this.state, 'onBoardingTasks', onBoardingTasks);
  }

  loadSubscription() {
    return apiClient.workspaceSubscriptions().then((data) => {
      Vue.set(this.state, 'plans', data.plans);
      Vue.set(this.state, 'addons', data.addons);
      Vue.set(this.state, 'subscription', data.subscription);
      Vue.set(this.state, 'billingConfig', data.billing_config);
    });
  }

  get trialEnded() {
    return (
      this.state.subscription &&
      this.trialPlans &&
      this.state.subscription.status == 'cancelled'
    );
  }

  updateSubscription() {
    return apiClient.updateWorkspaceSubscription().then((data) => {
      Vue.set(this.state, 'plans', data.plans);
      Vue.set(this.state, 'addons', data.addons);
      Vue.set(this.state, 'subscription', data.subscription);
      Vue.set(this.state, 'billingConfig', data.billing_config);
    });
  }

  updateGuideTheme(guideTheme) {
    return apiClient
      .updateGuideTheme(guideTheme)
      .then((data) => {
        this.setWorkspace(data);
      })

  }

  updateWorkspace(workspaceName?, logo = null, color = null) {
    return apiClient
      .updateWorkspace(workspaceName || this.workspace.displayName, logo, color)
      .then((data) => {
        if (!data || !data.workspaceSetting) {
          return;
        }
        this.setWorkspace(data);
      });
  }

  updateDigestEmailConfiguration(daily, weekly, monthly, academyWeekly) {
    return apiClient
      .updateDigestEmailConfiguration(daily, weekly, monthly, academyWeekly)
      .then((data) => {
        if (!data) {
          return;
        }
        Vue.set(
          this.state.workspace,
          'digestMailConfig',
          data.digestMailConfig
        );
      });
  }

  setOnboarded(onboarded) {
    Vue.set(this.state, 'onboarded', onboarded);
  }

  setProfile(profile) {
    Vue.set(this.state, 'profile', profile);
  }

  updateProfileData(profile) {
    this.state.profile = profile;
  }

  setHubSetting(workspace) {
    Vue.set(this.state, 'hubSetting', workspace.videoHubSetting);
  }

  setWorkspace(workspace) {
    Vue.set(this.state.workspace, 'id', workspace.id);
    Vue.set(this.state.workspace, 'name', workspace.name);
    Vue.set(this.state.workspace, 'displayName', workspace.displayName);
    Vue.set(this.state.workspace, 'logo', workspace.workspaceSetting.logoUrl);
    Vue.set(
      this.state.workspace,
      'color',
      workspace.workspaceSetting.brandColor
    );
    Vue.set(
      this.state.workspace,
      'guideBackgroundColor',
      workspace.workspaceSetting.guideBackgroundColor
    );
    Vue.set(
      this.state.workspace,
      'guideTextColor',
      workspace.workspaceSetting.guideTextColor
    );
    Vue.set(
      this.state.workspace,
      'guideButtonColor',
      workspace.workspaceSetting.guideButtonColor
    );
    Vue.set(
      this.state.workspace,
      'guideSpotlightColor',
      workspace.workspaceSetting.guideSpotlightColor
    )
    Vue.set(
      this.state.workspace,
      'primaryLanguage',
      workspace.workspaceSetting.primaryLang
    );
    Vue.set(
      this.state.workspace,
      'digestMailConfig',
      workspace.workspaceSetting.digestMailConfig
    );
    Vue.set(
      this.state.workspace,
      'primaryFont',
      workspace.workspaceSetting.primaryFont
    );
    Vue.set(this.state.workspace, 'configs', workspace.configs);
    if (this.state.workspace.configs.edgeApiEnabled) {
      apiClient.setEdgeApiEnabled(true)
    }
    Vue.set(this.state.workspace, 'features', workspace.features);
    Vue.set(this.state.workspace, 'videosCreated', workspace.videosCreated);
    Vue.set(this.state.workspace, 'trialEnd', workspace.trialEndAt);
    Vue.set(this.state.workspace, 'portalUrlPrefix', workspace.portalUrlPrefix);
    Vue.set(
      this.state.workspace,
      'academyUrlPrefix',
      workspace.academyUrlPrefix
    );
    Vue.set(this.state.workspace, 'academyViaSso', workspace.academyViaSso);
    Vue.set(this.state.workspace, 'academyEnabled', workspace.academyEnabled);
    Vue.set(
      this.state.workspace,
      'rootFolderId',
      workspace.workspaceSetting.rootFolderId
    );
  }

  setPermissions(permissions) {
    Vue.set(this.state, 'permissions', permissions);
  }

  setActiveWorkspaceMemberships(activeWorkspaceMemberships) {
    Vue.set(
      this.state,
      'activeWorkspaceMemberships',
      activeWorkspaceMemberships
    );
  }

  can(action, target) {
    return this.permissions.filter(
      (p) => p.action == action && p.target == target
    )[0];
  }

  eligible(action) {
    if (!this.state.workspace || !this.state.workspace.features) {
      return;
    }
    const feature = this.state.workspace.features.filter(
      (f) => f.name == action
    )[0];
    return (
      feature &&
      feature.enabled &&
      (feature.currentCount == undefined ||
        feature.currentCount < feature.maxCount ||
        feature.maxCount == -1)
    );
  }

  currentCollaboratorsCount() {
    if (!this.state.workspace || !this.state.workspace.features) {
      return 0;
    }
    const feature = this.state.workspace.features.filter(
      (f) => f.name == 'no_of_collaborators'
    )[0];
    return feature ? feature.currentCount : 0
  }

  feaureAllowed(action) {
    if (!this.state.workspace || !this.state.workspace.features) {
      return;
    }
    const feature = this.state.workspace.features.filter(
      (f) => f.name == action
    )[0];
    return feature && feature.enabled;
  }

  get permissions() {
    return this.state.permissions;
  }

  get activeWorkspaceMemberships() {
    return this.state.activeWorkspaceMemberships;
  }

  get canCloneVoice() {
    const w = this.activeWorkspaceMemberships.filter(
      (w) => w.workspace.id == this.workspace?.id
    )[0];
    return w && w.canCloneVoice;
  }

  get workspace() {
    return this.state.workspace;
  }

  get onBoardingTasks() {
    return this.state.onBoardingTasks;
  }

  get profile() {
    return this.state.profile;
  }

  get plans() {
    return this.state.plans;
  }

  get addons() {
    return this.state.addons;
  }

  get subscription() {
    return this.state.subscription;
  }

  get portalUrlPrefix() {
    return this.state.workspace.portalUrlPrefix;
  }

  get academyUrlPrefix() {
    return this.state.workspace.academyUrlPrefix;
  }

  get shareVideoUrlPrefix() {
    return this.state.workspace.portalUrlPrefix;
  }

  get workspaces() {
    return (
      this.profile &&
      this.profile.activeWorkspaceMemberships &&
      this.profile.activeWorkspaceMemberships.map((m) => m.workspace)
    );
  }

  get hubSetting() {
    return this.state.hubSetting;
  }

  get youtubeInteg() {
    return this.state.integrations && this.state.integrations.youtube;
  }

  get stripeInteg() {
    return this.state.integrations && this.state.integrations.stripe;
  }

  get recordingPreferences() {
    return this.state.profile?.recordingPreferences;
  }

  get trialPlans() {
    return ['starter', 'starter_2024', 'plus_2024', 'professional_2024', 'business_2024', 'enterprise_2024'].indexOf(this.state.subscription.plan_name) != -1;
  }
}
