export default {
  permissionDenied:
    'We need permisson to record your audio and video. Please grant permssion by clicking on the green security lock of the address bar',
  smart_capture: {
    title: 'Record your interactions',
    description:
      'You can later add voiceovers, edit and publish to <b>%{workspace_name}</b>',
    placeholder: 'Start by presenting your screen.',
    placeholder_install_ext:
      'Start creating your new video by installing the extension.',
    install_extension: 'Install Chrome Extension',
    share_and_record: 'Share screen and record',
    note: {
      tag: 'ALL-NEW',
      title:
        'Just present your screen. Trainn’s smart capture will do the rest.',
      description:
        'Useful for creating product walkthrough videos, or product demos.'
    }
  },
  screen: {
    title: 'Record your screen',
    description: 'Cast your screen and record instantly.',
    placeholder: 'Present your screen to start recording.',
    share_and_record: 'Share screen and record'
  },
  webcam: {
    title: 'Record yourself',
    description: 'Camera is on. Don’t forget to smile. :)',
    placeholder: 'Switch on your camera',
    start_recording: 'Start Recording'
  },
  stream_file: {
    title: 'Record over a PDF',
    description: 'Present your PDF and start recording.',
    placeholder:
      'Upload your presentation and record a video while navigating through the slides.',
    upload_pdf: 'Upload PDF',
    start_recording: 'Start Recording'
  },
  file: {
    title: 'Upload and edit',
    description: 'Upload & edit files on your device.',
    placeholder: 'Drag and drop files here',
    open_my_files: 'Or open my files…',
    upload_file: 'Upload Files...',
    note: 'Formats supported: .mp4, .mov, .jpeg, .png, .pdf',
    upload_and_edit: 'Upload and edit',
    proceed: 'Proceed',
    trimAndProceed: 'Trim and proceed',
    recent_files: {
      title: 'Choose file…',
      description:
        'You can import images, PDFs, or snippets from videos from your recent files or local storage.',
      empty: 'No recent files',
      video: 'Video',
      image: 'Image'
    }
  },
  clips_selector: {
    title: 'Make clips and use',
    description:
      'Choose to add snippets by specifying start and end time from the chosen video.',
    optional: '(optional)',
    make_clips: 'Make clips and use',
    start_time: 'Start time',
    end_time: 'End time',
    add_snippet: 'Add snippet',
    upload_and_edit: 'Upload and Edit'
  },
  start: 'Start Recording',
  pause: 'Pause',
  resume: 'Resume',
  cancel: 'Cancel',
  stop: 'Finish recording',
  last_updated: 'Last updated:',
  submit_video: 'Submit Video',
  publish_preview_video: 'Preview and publish',
  publish_video: 'Publish video',
  exit_preview: 'Exit preview',
  uploading_in_progress:
    'Hang tight! We are uploading your recording. This might take a few seconds.',
  camera_off: "Someone's too shy. <br /> Alright, someother time :)",
  max_upload_size: 'Maximum Upload Size: %{size}MB',
  onboarding: {
    title: "Before we begin...",
    tip: "Tip",
    of: "of",
    tip_1:
      "We will split the screen recording into multiple clips based on the interactions you do on the screen.<br/> <br/>We will automatically add zooms and highlights wherever required.",
    tip_2:
      "You can add human-like voiceovers after recording your screen. <br/><br/>Just type your voiceover for each clip while editing, trainn will automatically convert it into speech.",
    tip_3:
      'If you want to highlight an area on the screen without clicking it, use "Control (or) Command + Hover" on the area. <br/><br/>This will be treated as a block which you can edit later.',
    next_tip: "Next Tip",
    finish: "Finish & Start Recording",
  }
};
