export default {
  title: 'Hello, %{name}',
  description: `Explore the different types of videos you can create, edit and publish or add to your <b>library</b>.`,
  description_1: `Don’t worry! Just play around, you can always delete it later!`,
  paid_plans: 'More power, more benefits. ',
  start_creating: 'Create Now',
  demo: 'See it in action',
  walkthrough: {
    title: 'Create Walkthrough Video',
    // description:
    //   'Record quick demo/tour videos, add details and publish. <b>5 mins.</b>',
    description:
      'Create product walkthroughs, how-to videos, training videos, in just 5 minutes',
    step1: '<span class="t-td--border t-link">Install chrome plugin</span>.',
    step2: 'Record your browser application.',
    step3:
      'Add voice overs, edit and then <span class="t-td--border t-link">publish video</span>.',
    step4: 'Share video with your audience.',
    step1_disabled:
      'Get creator access from your admin to record a workflow of your app.',
    detailed: {
      video_title: 'Create Your First Video',
      guide_title: 'Create Your First Guide',
      install_extension: `Install the Chrome plugin, walk through your screen, let us capture your step-by-step actions into a perfect video.`,
      install_extension_guide: `Install the Chrome plugin, walk through your screen, let us capture your step-by-step actions into a perfect guide.`,
      record_video: `Turn your chrome plugin on, walkthrough the steps of your use case; Trainn captures these steps and generates a video out of it automatically.`,
      install_cta: 'Install Chrome Extension',
      differnt_types: 'Different Types of videos you can create',
      record_guide: 'Click on “Create &rarr; Create Guide”, share your screen and just walkthrough the steps of your usecase; See Trainn create a demo/guide out of it automatically.'
    }
  },
  pitch: {
    title: 'Convert Presentations into Videos',
    title_long: 'Convert Images & Presentations into Videos',
    description:
      'Convert your presentation slides or an image sequence into video. <b>5 mins.</b>',
    step1:
      '<span class="t-td--border t-link">Upload</span> your presentation as PDF or images.',
    step2:
      'Add voice overs, edit and then <span class="t-td--border t-link">publish video</span>.',
    step3: 'Share video with your audience.',
    step1_disabled:
      'Get creator access from your admin to convert your PDFs/presentations into high-quality videos in a few clicks.'
  },
  instant: {
    title: 'Create Instant Communication Videos',
    description:
      'Capture your screen with your cam, audio and share videos in a flash. <b>2 mins.</b>',
    step1: '<span class="t-td--border t-link">Install chrome plugin</span>.',
    step2:
      '<span class="t-td--border t-link">Record your screen,</span> cam, or both to create a video.',
    step3: 'Share video with your audience.',
    step1_disabled:
      'Get creator access from your admin to record quick instant videos.'
  },
  screen_recordings: {
    title_long: 'Convert your screen recordings to video',
    description:
      'Upload a screen recording, add voice and music and convert to a video and share in minutes.</b>'
  },
  collection: {
    title: 'Collection',
    description:
      'Group and organize your videos into collections to easily access and manage. <b>2 mins.</b>',
    step1:
      '<span class="t-td--border t-link">Create a collection</span>  using videos in your Library.',
    step1_disabled:
      'You do not have access to create collections. Kindly contact your admin.',
    step2: 'Share a collection with your audience.'
  },
  hub: {
    title: 'Knowledge Hub.',
    description:
      'Showcase the power of your product with step-by-step guidance, collections & modules. <b>10 mins.</b>',
    step1:
      '<span class="t-td--border t-link">Set up a knowledge hub</span>  using videos in your Library.',
    step1_disabled:
      'You do not have access to setup knowledge hub. Kindly contact your admin.'
  },
  integrations: {
    title: 'Integrations.',
    description:
      'Connect Trainn with your everyday apps, build workflows and expand your reach. <b>2 mins.</b>',
    step1:
      '<span class="t-td--border t-link">Connect Intercom</span> messenger with your Library.',
    step1_disabled:
      'You do not have access to perform this action. Kindly contact your admin',
    step2:
      '<span class="t-td--border t-link">Connect YouTube</span> channel for one-click uploads.',
    step2_disabled:
      'Reach out to this workspace admin to enable YouTube integration with your workspace'
  }
};
