import logger from '@/app/logger';
import * as Sentry from '@sentry/browser';
declare let grecaptcha: any;
declare let SENTRY_ENABLED: boolean;
declare let RECAPTCHA_KEY;

const createScript: (string) => Promise<void> = siteKey => {
  return new Promise((resolve, reject) => {
    const el = document.getElementById('recaptcha_script');
    if (!el) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute(
        'src',
        `https://www.google.com/recaptcha/api.js?render=${siteKey}`
      );
      recaptchaScript.setAttribute('async', 'true');
      recaptchaScript.setAttribute('id', 'recaptcha_script');
      recaptchaScript.onerror = error => {
        reject({
          message:
            'Problem with initialising google recaptcha, Try again later',
          error: 'recaptcha_not_loaded'
        });
      };
      recaptchaScript.onload = () => {
        grecaptcha.ready(() => {
          resolve();
        });
      };
      document.head.appendChild(recaptchaScript);
    } else {
      resolve();
    }
  });
};

class GoogleRecaptchaService {
  private siteKey: string;
  private loaderPromise: Promise<void> | null = null;

  constructor(siteKey) {
    this.siteKey = siteKey;
  }

  load() {
    if (!this.loaderPromise) {
      this.loaderPromise = createScript(this.siteKey);
    }
    return this.loaderPromise;
  }

  execute(action) {
    return this.load()
      .then(() => grecaptcha.execute(this.siteKey, { action }))
      .catch(error => {
        logger.error(error);
        if (SENTRY_ENABLED) {
          Sentry.captureException(error);
        }
      });
  }
}

export default new GoogleRecaptchaService(RECAPTCHA_KEY);
