import generic from './generic';
import nav from './nav';
import player from './player';
import editor from './editor';
import onboard from './onboard';
import home from './home';
import search from './search';
import message from './message';
import config from './config';
// to be removed
import hub from './hub';
import academy from './academy';
import dashboard from './dashboard';
import guide from './quick-start-guide';
import learners from './learners';
import learnerGroup from './learner-group';
import recorder from './recorder';
import stepGuide from './guide';

import article from './article';
export default {
  generic,
  nav,
  dashboard,
  guide,
  player,
  editor,
  onboard,
  home,
  message,
  config,
  hub,
  academy,
  search,
  learners,
  learnerGroup,
  recorder,
  article,
  stepGuide
};
