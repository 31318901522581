var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "modal",
    {
      attrs: {
        name: "upload-video",
        width: "420",
        adaptive: "",
        height: "auto",
        "pivot-y": _vm.$isMobile ? 1 : 0.5,
      },
      on: { "before-open": _vm.handleBeforeOpen },
    },
    [
      _c("UploadVideoComponent", {
        attrs: {
          "upload-version": _vm.uploadVersion,
          "can-collect-title": !_vm.uploadVersion,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }