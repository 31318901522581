
import Vue from 'vue';
import Component from 'vue-class-component';
import { scriptInjector } from '@/lib/utils/functions';
import { EventBus } from '@/lib/utils/event-bus';
import apiClient from '@/app/clients/api-client';

@Component({
  name: 'freshdesk-widget',
})
export default class FreshdeskWidget extends Vue {
  private showPopover = false;
  private widgetOpened = false;

  mounted() {
    if (this.$user && this.$user.profile){
      document.tidioIdentify = {
        distinct_id: this.$user.profile.uuid,
        email: this.$user.profile.email, 
        name: this.$user.profile.fullName,
      }
      }
    Promise.resolve().then(() => {
      scriptInjector({
        src: '//code.tidio.co/5pw31dqtd28oo9rmdqvfxsnd7o31mxtr.js',
      });
    }).catch((error) => {
      this.$error(error);
    });
  }

  // togglePopover() {
  //   document.removeEventListener('click', this.hidePopover);
  //   this.hideWidget();
  //   this.showPopover = !this.showPopover;
  //   if (!this.showPopover) {
  //     return;
  //   }
  //   window.setTimeout(() => {
  //     document.addEventListener('click', this.hidePopover);
  //   });
  // }

  // hidePopover(event) {
  //   if (event && event.target && event.target.closest('.t-help--popover')) {
  //     return;
  //   }
  //   this.showPopover = false;
  //   this.hideWidget();
  // }

  // hideWidget() {
  //   if (window.HubSpotConversations.widget) {
  //     const status = window.HubSpotConversations.widget.status();
  //     if (status.loaded) {
  //       window.HubSpotConversations.widget.close();
  //       // window.HubSpotConversations.widget.remove();
  //     }
  //   }
  // }

  // openFreshdeskWidget() {
  //   document.removeEventListener('click', this.hidePopover);
  //   this.showPopover = false;
  //   this.widgetOpened = true;
  //   const status = window.HubSpotConversations.widget.status();

  //   if (status.loaded) {
  //     window.HubSpotConversations.widget.open();
  //   } else {
  //     window.HubSpotConversations.widget.load({ widgetOpen: true });
  //     window.HubSpotConversations.widget.open();
  //   }
  // }

  // destroyed() {
  //   document.removeEventListener('click', this.hidePopover);
  //   this.hideWidget();
  // }
}
