import { EventEmitter } from 'events';

declare let window: any;

const createScript = () => {
  return new Promise((resolve, reject) => {
    const el = document.getElementById('auth2_script_id');
    if (!el) {
      const gplatformScript = document.createElement('script');
      gplatformScript.setAttribute(
        'src',
        'https://accounts.google.com/gsi/client'
      );
      gplatformScript.setAttribute('async', 'true');
      gplatformScript.setAttribute('defer', 'defer');
      gplatformScript.setAttribute('id', 'auth2_script_id');
      gplatformScript.onerror = error => {
        reject({
          message:
            "Problem with initialising 'Sign in with google'. If you are using an adblocker, whitelist '*.trainn.co'. We will be adding other ways to sign up in the upcoming weeks",
          error: 'google_script_not_loaded'
        });
      };
      gplatformScript.onload = () => {
        resolve(null);
      };
      document.head.appendChild(gplatformScript);
    } else {
      resolve(null);
    }
  });
};

class GoogleLoginService extends EventEmitter {
  private clientId!: string;
  setClientId(clientId: string) {
    this.clientId = clientId;
  }

  async load(container = 'google-signin-button') {
    await createScript();
    this.initOauth({
      client_id: this.clientId,
      container
    });
  }

  private initOauth(params) {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: params.client_id,
        callback: this.handleCredentialResponse
      });
      const parent = document.getElementById(params.container);
      window.google.accounts.id.renderButton(parent, {
        theme: 'outline',
        size: 'large',
        type: 'standard',
        logo_alignment: 'center',
        width: 400
      });
    } else {
      throw new Error('Google not loaded');
    }
  }

  handleCredentialResponse = response => {
    if (response.credential) {
      const credential = response.credential;
      this.emit('login', credential);
    } else {
      throw new Error('Please choose an account to sign in with google');
    }
  };

}

export default new GoogleLoginService();
