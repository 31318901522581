import Vue from 'vue';
import '@/app/hooks';
import router from '@/app/router/index';
import App from '@/app/App.vue';
import { i18n } from '@/app/plugins/i18n';
import Popover from 'vue-js-popover';
import { ControllerPlugin } from '@/app/player/controllers';
import { VPopover, VTooltip, VClosePopover } from 'v-tooltip';
import { FormPlugin } from '@/lib/form';
import ToastPlugin from '@/lib/components/toast';
import helpers from '@/app/plugins/helpers';
import '@/app/utils/polyfills.js';
import SlideWidth from '@/lib/components/transitions/SlideWidth.vue';
import Slide from '@/lib/components/transitions/Slide.vue';
import googleLoginService from '@/app/services/google-login-service';
import { AuthService } from './services/auth-service';
import apiClient from './clients/api-client';
import VuePortal from 'portal-vue';
import VModal from 'vue-js-modal/dist/index.nocss.js';
import 'vue-js-modal/dist/styles.css';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import WorkspaceUtils from './utils/workspace-utils';
import Sortable from '@/lib/directives/cb-sortable.js';
import VueKeepScroll from 'vue-keep-scroll';

declare let GOOGLE_SIGN_TOKEN: string;
declare let SENTRY_ENABLED: boolean;
declare let MF_ENABLED: boolean;
declare let SENTRY_DSN: string;
declare let ENVIRONMENT: string;
declare let CELLO_ENABLED: boolean;
declare let CELLO_ASSET_URL: string;

if (
  window.location &&
  window.location.pathname == '/videos/dg9NbK1OnrFkF6eB9fIrxQ/edit/preview'
) {
  window.location.href =
    'https://training.spotdraft.com/share/jMuEx0rTQyclqSJmOzP1PQ';
} else {
  if (SENTRY_ENABLED) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new VueIntegration({ Vue, attachProps: true })],
      ignoreErrors: ['Non-Error promise rejection captured']
    });
  }

  if (MF_ENABLED) {
    (window as any)._mfq = (window as any)._mfq || [];
    const mf = document.createElement('script');
    mf.type = 'text/javascript';
    mf.defer = true;
    mf.src =
      '//cdn.mouseflow.com/projects/4e02f961-13f6-47ee-9f10-1e14fe173847.js';
    document.getElementsByTagName('head')[0].appendChild(mf);
  }
  if (CELLO_ENABLED) {
    (window as any)._mfq = (window as any)._mfq || [];
    const mf = document.createElement('script');
    mf.type = 'module';
    mf.src = CELLO_ASSET_URL;
    document.getElementsByTagName('head')[0].appendChild(mf);
  }

  window.Chargebee.init({
    site: ENVIRONMENT === 'production' ? 'trainn' : 'trainn-test'
  });

  // Vue.use(VTooltip);
  Vue.directive('tooltip', VTooltip);

  Vue.use(Popover);

  Vue.directive('close-popover', VClosePopover);

  Vue.use(VModal, { dialog: true });

  // Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('t-slide-width', SlideWidth);
  Vue.component('t-slide', Slide);
  Vue.component('v-popover', VPopover);

  Vue.use(ControllerPlugin);
  Vue.use(FormPlugin);
  Vue.use(ToastPlugin);
  Vue.use(helpers);
  Vue.use(VuePortal);
  Vue.use(VueKeepScroll);
  Vue.directive('t-sortable', Sortable);

  googleLoginService.setClientId(GOOGLE_SIGN_TOKEN);

  if (WorkspaceUtils.parseWorkspaceName(window.location.hostname) != 'app') {
    window.location.href = WorkspaceUtils.getCommonUrl() + window.location.pathname + window.location.search;
  }
  else {
    AuthService.setInstance(new AuthService());

    apiClient.setAuthService(AuthService.getInstance());
    AuthService.getInstance()
      .loadJwt()
      .then(() => {
        const app = new Vue({
          el: '#app',
          router,
          i18n,
          render: h => h(App)
        });
      });
  }
}