<template>
  <div id="t-toast" class="t-toast">
    <transition name="toast-list" tag="div" class="t-toast__list">
      <div
        v-if="!$isEmpty(toast)"
        class="t-toast__list-item"
        :class="type(toast.type)"
      >
        <div class="t-toast__content">
          <div class="t-toast__icon">
            <i
              v-if="toast.type == 'success'"
              class="fas fa-check-circle fa-fw"
            ></i>
            <i
              v-else-if="toast.type == 'error'"
              class="fas fa-exclamation-circle fa-fw"
            ></i>
            <i v-else class="fas fa-info-circle fa-fw"></i>
          </div>
          <div v-if="toast.message" class="t-toast__content">
            {{ toast.message }}
          </div>
          <t-button
            v-if="toast.dismissible"
            variant="action"
            class="t-toast__close"
            @click="$remove(toast.id)"
          >
            <i class="fas fa-times fa-fw"></i>
          </t-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ToastType } from '@/lib/components/toast/types.ts';
import TButton from '@/lib/form/button/Button.vue';

export default {
  name: 'TToast',
  components: {
    TButton
  },
  data() {
    return {
      content: []
    };
  },
  computed: {
    toast() {
      return this.content[0];
    }
  },
  methods: {
    type(type) {
      switch (type) {
        case ToastType.Danger:
          return 'danger';
        case ToastType.Success:
          return 'success';
        case ToastType.Info:
          return 'info';
      }
    }
  }
};
</script>
