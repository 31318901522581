var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "t-create__content" }, [
    _c("div", { staticClass: "t-create__main t-create__bg" }, [
      _c("div", { staticClass: "t-create__steps" }, [
        _c("div", { staticClass: "t-create__step" }, [
          _vm.partnerMode
            ? _c("img", {
                staticClass: "t-mar--r-md",
                attrs: {
                  src: require("@/lib/assets/images/create-video/browser.svg"),
                  height: "80",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "t-create__grow" }, [
            _vm.partnerMode
              ? _c("div", { staticClass: "t-fw--bolder" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("home.create_video.walkthrough.step1.title")
                      ) +
                      "\n          "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "t-tc--muted" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("home.create_video.walkthrough.step1.desc")) +
                    "\n            "
                ),
                !_vm.partnerMode
                  ? [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("home.create_video.walkthrough.step2.desc")
                          ) +
                          "\n            "
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.partnerMode
          ? _c("div", { staticClass: "t-create__step" }, [
              _c("div", { staticClass: "t-create__grow t-mar--r-md" }, [
                _c("div", { staticClass: "t-fw--bolder" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("home.create_video.walkthrough.step2.title")
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "t-tc--muted" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("home.create_video.walkthrough.step2.desc")
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: require("@/lib/assets/images/create-video/install_cs.svg"),
                  height: "80",
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.partnerMode
          ? _c("div", { staticClass: "t-create__step" }, [
              _c("img", {
                attrs: {
                  src: require("@/lib/assets/images/create_video.gif"),
                  height: "200px",
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "t-create__divider t-create__step" }),
        _vm._v(" "),
        _c("div", { staticClass: "t-create__step t-flex--dc t-ta--center" }, [
          _c(
            "a",
            {
              staticClass:
                "t-create__button t-button t-button--small t-button--standard",
              attrs: {
                href: _vm.partnerMode
                  ? "https://img.trainn.co/self-carestack/trainn-extension-v1.3.1.zip"
                  : _vm.getExtensionURL,
                target: "_blank",
              },
            },
            [
              !_vm.partnerMode
                ? _c("img", {
                    attrs: {
                      src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/Trainn-logo-blue.svg"),
                      height: "20",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "t-mar--l-mi" }, [
                _vm._v(_vm._s(_vm.$t("home.create_video.install_extension"))),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "t-fs--caption t-mar--b-no t-mar--t-xs t-tc--muted t-fw--normal",
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("home.create_video.not_installed")) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "t-create__aside" }, [
      _c("p", { staticClass: "t-mar--r-xl" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("home.create_video.walkthrough.desc_long")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "t-mar--t-sm t-mar--r-xl" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("home.create_video.watch_video")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "t-mar--t-lg" }, [
        _c("a", { attrs: { href: _vm.videoTutorialUrl, target: "_blank" } }, [
          _c("img", {
            attrs: {
              src: require("@/lib/assets/images/video-thumbnails/walkthrough.svg"),
              height: "200px",
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }