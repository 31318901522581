
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import TInput from '@/lib/form/input/Input.vue';
import TButton from '@/lib/form/button/Button.vue';
import apiClient from '@/app/clients/api-client';
import { EventBus } from '@/lib/utils/event-bus';
import { ToastType } from '@/lib/components/toast/types';

@Component({
  name: 'upload-video',
  components: { TButton, TInput },
  props: {
    isImportMode: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    canCollectTitle: {
      type: Boolean,
      default: true,
    },
    uploadVersion: {
      type: Boolean, 
      default: false,
    }
  },
})
export default class UploadVideoComponent extends Vue {
  private title = '';
  private invalidTitle = '';
  private file = '';
  private fileInvalid = false;
  private createLoader = false;
  private isImportMode;
  private headerTitle;
  private inputLabel;
  private inputPlaceholder;
  private canCollectTitle;
  private uploadVersion;

  get attachmentTypes() {
    return ['video/mp4', 'video/quicktime'];
  }

  uploadFile(file) {
    this.file = file;
  }

  uploadVideo() {
    this.invalidTitle = '';
    if (this.canCollectTitle && !this.title) {
      this.invalidTitle = this.$t('home.create_video.invalid_input') + '';
      return;
    }
    this.fileInvalid = false;
    if (!this.file) {
      this.fileInvalid = true;
      return;
    }
    if (this.createLoader) {
      return;
    }
    this.createLoader = true;
    if (this.uploadVersion) {
      this.uploadNewVersion();
    } else {
      this.createVideo();
    }
  }

  resetData() {
    this.title = '';
    this.invalidTitle = '';
    this.file = '';
    this.fileInvalid = false;
    this.createLoader = false;
  }

  createVideo() {
    apiClient
      .createVideo(
        this.title || 'Untitled video',
        this.file,
        'manual',
        this.$route.params.folderId
      )
      .then(({ id }) => {
        this.$modal.hide('create-video');
        if (this.isImportMode) {
          this.resetData();
          this.$emit('video-created', id);
          return;
        }
        this.$router.push({
          name: 'edit_video',
          params: {
            videoId: id,
          },
        });
      })
      .catch((e) => {
        this.createLoader = false;
        this.$error(e);
      });
  }

  uploadNewVersion() {
    apiClient
      .uploadNewVerison(
        this.$route.params.videoId,
        null,
        this.file,
        'manual',
        this.$route.params.folderId
      )
      .then(({ id }) => {
        this.$modal.hide('create-video');
        if (this.isImportMode) {
          this.resetData();
          this.$emit('video-created', id);
          return;
        }
        this.$show({
          type: ToastType.Info,
          message: this.$t('editor.uploaded_video.title'),
        })
        EventBus.$emit('resync-upload-video');
        this.$modal.hide('upload-video');
      })
      .catch((e) => {
        this.createLoader = false;
        this.$error(e);
      });
  }

  get uploadTitle() {
    return this.headerTitle || this.$t(`home.create_video.manual_upload.title`);
  }

  get uploadInputLabel() {
    return this.inputLabel || this.$t('home.create_video.input_label');
  }

  get uploadInputPlaceholder() {
    return (
      this.inputPlaceholder || this.$t('home.create_video.input_placeholder')
    );
  }
}
