var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.name
    ? _c(
        "transition",
        { attrs: { name: _vm.name, mode: _vm.mode } },
        [_vm._t("default")],
        2
      )
    : !_vm.isList
    ? _c(
        "transition",
        {
          attrs: { mode: "out-in" },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            "after-leave": _vm.afterLeave,
            leave: _vm.leave,
            "before-leave": _vm.beforeLeave,
          },
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "transition-group",
        {
          attrs: { mode: "out-in" },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            "after-leave": _vm.afterLeave,
            leave: _vm.leave,
            "before-leave": _vm.beforeLeave,
          },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }