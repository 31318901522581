var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _vm.classList },
    [
      _vm.label
        ? _c("tube-label", {
            staticClass: "t-input__label",
            attrs: { label: _vm.label, required: _vm.required, name: _vm.name },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading || _vm.search
        ? _c(
            "div",
            { staticClass: "t-input__group" },
            [
              _vm.search
                ? _c(
                    "label",
                    {
                      class: {
                        "t-pad--l-sm": _vm.variant != "text",
                        "t-mar--t-no": _vm.loading,
                      },
                      attrs: { for: _vm.name },
                    },
                    [
                      _vm.loading
                        ? _c("i", {
                            staticClass:
                              "fas fa-circle-notch fa-fw fa-spin t-mar--r-mi t-tc--muted",
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/lib/assets/images/nav-icons/search.svg"),
                              height: "16",
                            },
                          }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      ref: "text",
                      staticClass: "t-input__control",
                      class: {
                        "t-pad--l-no":
                          _vm.variant != "text" && _vm.type == "search",
                      },
                      attrs: {
                        id: _vm.name,
                        disabled: _vm.disabled,
                        required: _vm.required,
                        readonly: _vm.readonly,
                        placeholder: _vm.placeholder,
                        autocomplete: _vm.autocomplete,
                        autofocus: _vm.autofocus,
                        maxLength: _vm.maxLength,
                        min: _vm.min,
                        max: _vm.max,
                        step: _vm.step,
                        name: _vm.name,
                        type: _vm.type,
                        accept: _vm.accept,
                      },
                      domProps: { value: _vm.internalValue },
                      on: {
                        focus: _vm.onFocus,
                        input: _vm.onInput,
                        change: _vm.onChange,
                        blur: _vm.onBlur,
                      },
                    },
                    "input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading && !_vm.search
        ? _c("div", { staticClass: "t-input__loader" }, [
            _c("i", { staticClass: "fas fa-circle-notch fa-fw fa-spin" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.invalidMessage
        ? _c("div", { staticClass: "t-input__error" }, [
            _vm._v(_vm._s(_vm.invalidMessage)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c("div", {
            staticClass: "t-input__description",
            domProps: { innerHTML: _vm._s(_vm.description) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }