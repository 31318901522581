var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

var hasClass = function hasClass(ele, cls) {
  return (
    ele &&
    ele.className &&
    !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
  );
};

var addClass = function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
};

var removeClass = function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
};

var getSiblingsWithMe = function getSiblingsWithMe(ele) {
  var parent = ele.parentElement;
  var firstChild = parent.firstChild;
  var array = [firstChild];
  while (firstChild.nextSibling) {
    array.push(firstChild.nextSibling);
    firstChild = firstChild.nextSibling;
  }
  return array;
};

var getEventPath = function getEventPath(event) {
  if (event.path) {
    return event.path;
  }
  var path = [];
  var target = event.target;
  while (target.parentNode) {
    path.push(target);
    target = target.parentNode;
  }
  path.push(document, window);
  return path;
};

var EventListeners = (function() {
  function EventListeners() {
    _classCallCheck(this, EventListeners);
  }

  _createClass(EventListeners, null, [
    {
      key: 'mousedown',
      value: function mousedown(event) {
        var element = event.currentTarget;
        var sortHandle =
          element.attributes['sort-handle'] &&
          element.attributes['sort-handle'].value;
        if (sortHandle) {
          var paths = getEventPath(event);
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (
              var _iterator = paths[Symbol.iterator](), _step;
              !(_iteratorNormalCompletion = (_step = _iterator.next()).done);
              _iteratorNormalCompletion = true
            ) {
              var path = _step.value;

              if (hasClass(path, sortHandle)) {
                break;
              }
              if (path == element.parentElement) {
                return;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        // clone element
        var clonedElement = this.cloneElement(event, element);
        // attach to the body
        element.parentElement.appendChild(clonedElement);
        element.parentElement.$dragElement = clonedElement;
        clonedElement.$srcElement = element;
        this.setMousemoveEventFunction(clonedElement, element);
        addClass(clonedElement, 'clone');
        addClass(element.parentElement, 'sort-on');
        addClass(element, 'drag-on');
      }
    },
    {
      key: 'mouseover',
      value: function mouseover(event) {
        if (hasClass(event.currentTarget.parentElement, 'sort-on')) {
          var clonedElement = event.currentTarget.parentElement.$dragElement;
          addClass(event.currentTarget, 'hover-on');
          if (event.currentTarget.style) {
            var listStyle = event.currentTarget.attributes['list-style'] && event.currentTarget.attributes['list-style'].value;
            if(listStyle && listStyle == 'horizontal'){
              event.currentTarget.style.paddingLeft = 12 + 'px'
            }
            else if(listStyle && listStyle == 'dotted-box') {
              event.currentTarget.style.paddingTop = 101 + 'px';
            }else {
              event.currentTarget.style.paddingTop = clonedElement.clientHeight + 'px';
            }
          }
        }
      }
    },
    {
      key: 'mouseleave',
      value: function mouseleave(event) {
        removeClass(event.currentTarget, 'hover-on');
        event.currentTarget.style.paddingTop = '0px';
        event.currentTarget.style.paddingLeft = '0px';
      }
    },
    {
      key: 'mouseup',
      value: function mouseup(event, binding) {
        if (hasClass(event.currentTarget.parentElement, 'sort-on')) {
          var parent = event.currentTarget.parentElement;
          var dragElement = parent.$dragElement;
          var element = event.currentTarget;
          var siblings = getSiblingsWithMe(element);
          event.currentTarget.style.paddingTop = '0px';
          event.currentTarget.style.paddingLeft = '0px';
          if (!binding.value) {
            var destIndex = siblings.indexOf(element);
            var srcIndex = siblings.indexOf(dragElement.$srcElement);
            if (destIndex - srcIndex == 1) {
              var referenceNode = siblings[siblings.indexOf(element) + 1];
              parent.insertBefore(dragElement.$srcElement, referenceNode);
            } else {
              var _referenceNode = siblings[siblings.indexOf(element)];
              parent.insertBefore(dragElement.$srcElement, _referenceNode);
            }
          } else {
            binding.value(
              siblings.indexOf(element),
              siblings.indexOf(dragElement.$srcElement)
            );
          }
        }
      }
    },
    {
      key: 'cloneElement',
      value: function cloneElement(event, element) {
        var clonedElement = element.cloneNode(true);
        clonedElement.style.width = element.offsetWidth + 'px';
        clonedElement.style.height = element.offsetHeight + 'px';
        clonedElement.style.position = 'fixed';
        clonedElement.style.top = event.clientY + 'px';
        clonedElement.style.left = element.getBoundingClientRect().x + 'px';
        clonedElement.style.pointerEvents = 'none';
        clonedElement.$offsetLeft =
          event.clientX - element.getBoundingClientRect().x;
        return clonedElement;
      }
    },
    {
      key: 'setMousemoveEventFunction',
      value: function setMousemoveEventFunction(clonedElement, element) {
        var output = function output(event) {
          clonedElement.style.top = event.clientY + 'px';
          clonedElement.style.left =
            event.clientX - clonedElement.$offsetLeft + 'px';
        };

        var mouseupEventListener = function mouseupEventListener() {
          delete element.parentElement.$dragElement;
          element.parentElement.removeChild(clonedElement);
          document.removeEventListener('mousemove', output);
          document.removeEventListener('mouseup', mouseupEventListener);
          removeClass(element.parentElement, 'sort-on');
          removeClass(element, 'drag-on');
          // find element that has hover-on and remove the class
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (
              var _iterator2 = element.parentElement.childNodes[
                  Symbol.iterator
                ](),
                _step2;
              !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done);
              _iteratorNormalCompletion2 = true
            ) {
              var child = _step2.value;

              removeClass(child, 'hover-on');
              if (child.style) {
                child.style.paddingTop = '0px';
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        };

        document.addEventListener('mousemove', output);
        document.addEventListener('mouseup', mouseupEventListener);
      }
    },
    {
      key: 'updatePosition',
      value: function updatePosition(event, binding) {
        const newIndex = event.detail.newIndex;
        const oldIndex = event.detail.oldIndex;

        binding.value(newIndex, oldIndex);
      }
    }
  ]);

  return EventListeners;
})();

var index = {
  bind: function bind(el, binding) {
    el.addEventListener('mousedown', function(event) {
      EventListeners.mousedown(event, binding);
    });

    el.addEventListener('mouseup', function(event) {
      EventListeners.mouseup(event, binding);
    });
    el.addEventListener(
      'mouseover',
      EventListeners.mouseover.bind(EventListeners)
    );
    el.addEventListener(
      'mouseleave',
      EventListeners.mouseleave.bind(EventListeners)
    );
    el.addEventListener('update-position', function(event) {
      EventListeners.updatePosition(event, binding);
    })
  }
};

export default index;
