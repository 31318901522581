
import Vue from 'vue';
import Component from 'vue-class-component';
declare let PARTNER_MODE: boolean;

@Component({
  name: 'frame-placeholder',
  props: ['size', 'spinner']
})
export default class Loader extends Vue {
  private partnerMode = PARTNER_MODE;
}
