export default {
  title: 'Knowledge Hub',
  title_short: 'Knowledge Hub',
  // description:
  //   'You can configure videos and learning paths that needs to shown in the video academy along with analytics, public/private access, theme and text copy. ',
  description:
    'Our fully customizable knowledge hub makes it easy to host an entire collection of knowledge content on one elegant, on-brand page. Showcase your on-demand training videos, feature walkthroughs and interactive giudes using your own Knowledge Hub.',
  button: {
    disable: 'Disable Academy',
    enable: 'Enable Knowledge Hub',
    email_activity: 'Email user activity',
  },
  tab: {
    settings: 'Settings',
    analytics: 'Analytics',
    custom_domain: 'Custom Domain',
    theme: 'Theme',
    guide_theme: 'Guide Theme',
    copy: 'Copy',
    content: 'Content',
  },
  auth_type: {
    label: 'Authentication',
    desc: 'Configure authentication settings for your knowledge hub.',
    public: 'Public Access',
    public_desc: 'No authentication required. Anyone can access the knowledge hub',
    private: 'Private Access',
    private_desc: 'Only authenticated customers can access the knowledge hub',
    sso: "Use your application's authentication (SSO)",
    sso_documentation_link:
      "Check this <a target='_blank' href='https://help.trainn.co/share/dabTAiuVyypmrtWwGmDL9w'>documentation</a> on how to integrate SSO with knowledge hub.",
    sso_desc:
      'Allows members to sign in to your knowledge hub using your application authentication',
    train_login: 'Use Email Authentication',
    train_login_desc:
      'Allows members to sign in to your knowledge hub using their Email & Password',
    train_auth_customer_signup: 'Allow anyone to sign up',
    train_auth_customer_signup_desc:
      'Uncheck to restrict the access only to existing customers',
    login_url: 'Login URL',
    login_url_desc:
      'URL to which the user needs to be redirected for authentication.',
    saml: 'Single Sign-On SAML',
    saml_documentation_link:
      "Check this <a target='_blank' href='https://help.trainn.co/share/GnT3UWKML0YoHBOXQtirIA'>documentation</a> on how to integrate SSO SAML with knowledge hub.",
    saml_login_url: 'Login URL',
    saml_login_url_desc: 'Users will be redirected to this URL to login.',
    saml_certificate: 'SAML Certificate',
    saml_certificate_desc:
      'SAML X.509 certificate to authenticate with your Identity Provider',
    logout_url: 'Remote Logout URL (optional)',
    logout_url_desc:
      'Users will be redirected to this URL when they logout from Hub',
  },
  general_settings: 'General Settings',
  goto_url: 'Goto url for your application',
  allow_crawling: 'Allow google to crawl all the videos',
  private_crawling: 'Allow google to crawl login page',
  analytics: {
    allow_ga_label: 'Enable Google Analytics',
    allow_ga_desc:
      'Connect with Google Analytics to see how your users find and interact with your Knowledge Hub.',
    tracking_code_label: 'Google Analytics tracking code - Deprecated',
    measurement_code_label: 'GA-4 Measurement Id',
    tracking_code_desc: 'Copy and paste your Google Analytics tracking code',
    measurement_code_desc:
      'Copy and paste your Google Analytics - 4 Measurement Id',
    cross_origin_tracking_label: 'Enable cross origin tracking',
    origin_ids_label: 'Origin Ids',
    origin_ids_desc: 'Use comma to separate domain names',
  },
  theme: {
    primary_color: 'Primary Color',
    placeholder: '#FFFFFF',
    favicon: 'Upload your favicon',
    faviconDescription: 'Recommended size is 32 x 32 pixels',
    bannerImage: 'Upload your banner image',
    bannerDescription:
      "Recommended size is 1440 x 780 pixels. You can download banner images for free from <a href='https://unsplash.com/' target='_blank'>Unsplash.</a>",
    logo: 'Upload your logo',
    logoDescription: 'Width: 100px min, 1400px max.',
  },
  copy: {
    title: 'Knowledge hub title',
    title_placeholder: `{workspace_name} Knowledge hub`,
    description: 'Knowledge hub description',
    login_description: 'Knowledge hub login description',
    desc_placeholder:
      'A description of your workspace. Example, One stop knowledge hub to learn everything about {workspace_name}',
    login_desc_placeholder:
      "We have created videos for you on how to use our product. Login to view these videos and understand our product's capabilities.",
  },
  content: {
    desc: 'Add, remove, and rearrange content that needs to be displayed in your hub.',
    collection_title: 'Collections',
    collection_desc:
      'Add, remove, and rearrange collections that needs to be displayed in your hub.',
    recommended_title: 'Recommended Videos',
    recommended_desc: "Add the videos you'd like to recommend to your user.",
    add_collection: 'Add Collection',
    available_collections: 'Available collections',
    add_videos: 'Add Videos',
    availabe_videos: 'Available Videos',
    advanced_mode: {
      desc: 'Group collections, manage content and much more.',
      cta: 'Switch to Advanced mode',
    },
    categories: {
      title: 'Categories',
      // desc:
      //   'Add, remove, and rearrange learning paths and group them into categories to be displayed in the academy',,
      desc: 'Add collections of a similar topic into a category.',
      add: {
        title: 'Add Category',
        label: 'Category Name',
        placeholder: 'Default Category',
      },
      edit: {
        title: 'Edit Category',
        label: 'Category Name',
      },
      collections: 'Total number of collections',
      empty:
        'No collections added yet. Add, remove, and rearrange collections that has to be displayed under this category in the hub.',
    },
  },
  invalid_url_format: 'Please enter a valid url',
  portal_url: 'Your portal url',
  trigger_email_activity: 'User activity will be sent to your mail',
};
