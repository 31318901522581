var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "modal",
    {
      attrs: {
        name: "create-video",
        width: "990px",
        adaptive: "",
        height: "auto",
        classes: "t-overflow--scroll",
        "pivot-y": _vm.$isMobile ? 1 : 0.5,
      },
      on: {
        opened: _vm.modalOpened,
        "before-open": _vm.beforeOpen,
        "before-close": _vm.resetData,
      },
    },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.videoType
          ? _c(
              "div",
              {
                key: "video-type",
                staticClass: "t-create",
                class: { "t-pad--t-xl": !_vm.isNewEditorEnabled },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "t-create__content t-create--narrow",
                    class: [
                      {
                        "t-mar--t-xl": !_vm.$isMobile,
                        "t-mar--b-xl": !_vm.isNewEditorEnabled,
                      },
                    ],
                  },
                  [
                    !_vm.$isMobile
                      ? _c(
                          "div",
                          {
                            staticClass: "t-create__main t-mar--t-xl",
                            class: { "t-mar--r-xxl": !_vm.isNewEditorEnabled },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/lib/assets/images/icons/animation-play.svg"),
                                height: "48",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "h1",
                              { staticClass: "t-fw--bolder t-mar--t-st" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("home.create_video.title")) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "t-tc--muted" }, [
                              _vm._v(_vm._s(_vm.$t("home.create_video.desc"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "create__aside" },
                      [
                        _vm.$isMobile
                          ? _c("h5", { staticClass: "t-mar--b-st" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("home.create_video.title")) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isNewEditorEnabled
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "t-create__item t-flex t-flex--dr t-mar--b-st t-pos--rel",
                                  on: {
                                    click: function ($event) {
                                      _vm.$router.push(
                                        "/record" +
                                          (_vm.$route.params.folderId
                                            ? `?folderId=${_vm.$route.params.folderId}`
                                            : "")
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "t-create--chip" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "home.create_video.introducing"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/lib/assets/images/zing-logo.svg"),
                                      alt: "Zing by Trainn logo",
                                      height: "40",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "t-create__desc t-flex t-flex--dc t-mar--l-md t-ta--left",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "home.create_video.zing_by_trainn"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "t-flex t-flex--aic t-mar--t-mi t-create--btn",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("nav.button.create")
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "mdi mdi-chevron-double-right mdi-18px",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "t-create__items" }, [
                          _c(
                            "div",
                            {
                              staticClass: "t-create__item",
                              class: { "t-disabled": _vm.$isMobile },
                              attrs: { id: "create-walkthrough-modal" },
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoType("screen_recording")
                                },
                              },
                            },
                            [
                              _vm.partnerMode
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/lib/assets/images/icons/web.svg"),
                                      height: "20",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/trainn-logo-walkthrough-gradient.svg"),
                                      height: "20",
                                    },
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "t-fs--subtitle t-mar--l-st" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "home.create_video.walkthrough.title"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                  !_vm.isNewEditorEnabled
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "t-guide__tag t-pad--l-mi",
                                        },
                                        [_vm._v("Popular")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "t-create__item",
                              class: { "t-disabled": _vm.$isMobile },
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoType("desktop_recording")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/lib/assets/images/icons/desktop-mac.svg"),
                                  height: "20",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "t-fs--subtitle t-mar--l-st" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "home.create_video.desktop_recording.title"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "t-create__item",
                              class: { "t-disabled": _vm.$isMobile },
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoType("mobile_recording")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/lib/assets/images/icons/cellphone.svg"),
                                  height: "20",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "t-fs--subtitle t-mar--l-st" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "home.create_video.mobile_recording.title"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "t-create__item",
                              class: { "t-disabled": _vm.$isMobile },
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoType("instant")
                                },
                              },
                            },
                            [
                              _vm.partnerMode
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/lib/assets/images/icons/camera.svg"),
                                      height: "20",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/trainn-logo-instant-gradient.svg"),
                                      height: "20",
                                    },
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "t-fs--subtitle t-mar--l-st" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "home.create_video.instant.title"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "t-create__item",
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoType("images")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/lib/assets/images/icons/file-image.svg"),
                                  height: "20",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "t-fs--subtitle t-mar--l-st" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("home.create_video.images.title")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "t-create__item",
                              on: {
                                click: function ($event) {
                                  return _vm.setVideoType("pdf")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/lib/assets/images/icons/file-image.svg"),
                                  height: "20",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "t-fs--subtitle t-mar--l-st" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("home.create_video.pdf.title")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            )
          : _c(
              "div",
              { staticClass: "t-create" },
              [
                _c(
                  "div",
                  { staticClass: "t-create__nav", on: { click: _vm.goBack } },
                  [
                    _c("i", { staticClass: "mdi mdi-arrow-left mdi-32px" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("home.create_video.back_to_main"))),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.videoType == "screen_recording"
                  ? _c("CreateWalkthroughModal")
                  : _vm.videoType == "instant"
                  ? _c("CreateInstantModal")
                  : _vm.videoType == "desktop_recording" ||
                    _vm.videoType == "mobile_recording"
                  ? _c("CreateDesktopMobileWalkthroughModal", {
                      attrs: { type: _vm.videoType },
                    })
                  : _vm.videoType == "images" || _vm.videoType == "pdf"
                  ? _c("CreatePresentationModal", {
                      attrs: { type: _vm.videoType },
                    })
                  : _vm._e(),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }