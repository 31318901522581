
import Vue from 'vue';
import Component from 'vue-class-component';
import TButton from '@/lib/form/button/Button.vue';
import { Watch } from 'vue-property-decorator';
import { SearchButtonRoutes } from '@/app/constants/full-page-routes';

@Component({
  name: 't-header',
  props: ['isFullPage'],
  components: {
    TButton,
  },
})
export default class TrainnHeader extends Vue {
  private switchLoader = false;
  private search = false;
  private showSearchButton = false;

  constructor() {
    super();
  }

  created() {
    this.checkRouteForSearchButton();
  }

  mounted() {}

  @Watch('$route')
  onRouteChange() {
    this.checkRouteForSearchButton();
  }

  checkRouteForSearchButton() {
    const path = window.location.pathname;
    const searchInRoutes = SearchButtonRoutes.some((route) =>
      path.match(route)
    );
    const isFolders = this.$route.name == 'folders_home';
    this.showSearchButton = searchInRoutes || isFolders;
  }

  openSearch() {
    this.search = true;
    window.setTimeout(() => {
      document.addEventListener('click', this.hideSearch);
    });
  }

  closeSearch() {
    this.search = false;
    document.removeEventListener('click', this.hideSearch);
  }

  hideSearch(event) {
    if (event && event.target && event.target.closest('.t-header__search')) {
      return;
    }
    document.removeEventListener('click', this.hideSearch);
    this.search = false;
  }

  destroyed() {
    document.removeEventListener('click', this.hideSearch);
  }
}
