
import Vue from 'vue';
import Component from 'vue-class-component';
import TButton from '@/lib/form/button/Button.vue';
declare let PARTNER_MODE: boolean;

@Component({
  name: 'create-walkthrough-modal',
  components: {
    TButton
  }
})
export default class CreateWalkthroughModal extends Vue {
  private partnerMode = PARTNER_MODE;

  get engagementToken() {
    return this.$user && this.$user.state.engagementToken;
  }

  get queryParams() {
    return this.engagementToken ? `?et=${this.engagementToken}` : '';
  }

  get videoTutorialUrl() {
    return `https://wiki.portal.trainn.co/share/bf93c553-3d4d-45b9-be04-5cf131d871df${this.queryParams}`;
  }

  get getExtensionURL() {
    if(this.newExtensionEnabled) {
      return 'https://chromewebstore.google.com/detail/trainn/cfbmkahmokgjodjiiknohpnjponhnoif';
    }
    return 'https://chromewebstore.google.com/detail/trainn/holcgkoaafmeehahhkpmpidmmdmbokfo';
  }

  get newExtensionEnabled() {
    return this?.$user?.workspace?.configs?.newExtensionEnabled;
  }
}
