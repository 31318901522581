import WorkspaceUtils from '../utils/workspace-utils';
import GlobalUtils from '@/lib/utils/global-utils';
import apiClient from '../clients/api-client';
import { EventBus } from '@/lib/utils/event-bus';
declare let WALKTHROUGH_ID: string;
declare let INSTA_ID: string;

const TOKEN_IDENTIFIER = 'tt';
const UPDATED_AT_IDENTIFIER = 'st';
const WORKSPACE = 'w';
const EMAIL_KEY = 'em';
const PASSWORD_SET = 'emp';
const IS_SSO = 'sso';

export const REPEAT_CUSTOMER = 'rc';

const eventMethod = window.addEventListener
  ? 'addEventListener'
  : 'attachEvent';
const eventer = window[eventMethod];
const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

export class AuthService {
  private workspaceName!: string | undefined;
  jwtToken!: string | undefined;
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  trialEnded = false;
  isSso = false;
  private logout_url: string | undefined;

  private static _instance: AuthService;

  constructor() {
    eventer(messageEvent, this.authEventListener.bind(this));
  }

  static setInstance(authService: AuthService) {
    this._instance = authService;
  }

  static getInstance() {
    return this._instance;
  }

  get isLoggedIn() {
    return !!this.jwtToken;
  }

  getWorkspaceName() {
    return this.workspaceName;
  }

  setEmail(email, passwordSet) {
    this.storeValue(EMAIL_KEY, email);
    this.storeValue(PASSWORD_SET, passwordSet ? 'true' : 'false');
  }

  setWorkspaceName(workspaceName, isSSo = 'false') {
    this.workspaceName = workspaceName;
    this.storeValue(WORKSPACE, workspaceName);
    this.storeValue(IS_SSO, isSSo);
  }

  getSso() {
    return this.getValue(IS_SSO);
  }

  setJwt(token, runTimeIds: string[] | undefined = undefined) {
    this.storeValue(REPEAT_CUSTOMER, 'true');
    this.storeValue(TOKEN_IDENTIFIER, token);
    this.storeValue(UPDATED_AT_IDENTIFIER, '' + Date.now());
    this.jwtToken = token;
    return this._syncToken(runTimeIds);
  }

  loadJwt() {
    const token = this.getValue(TOKEN_IDENTIFIER);
    const st = this.getValue(UPDATED_AT_IDENTIFIER);
    const storedWorkspace = this.getValue(WORKSPACE);
    if (
      token &&
      st &&
      (storedWorkspace != 'app')
    ) {
      this.workspaceName = storedWorkspace;
      this.jwtToken = token;
    }

    return this._syncToken();
  }

  copyJwtTo(workspaceName) {
    this.setWorkspaceName(workspaceName);
    return this._syncToken();
  }

  logout() {
    const email = this.getValue(EMAIL_KEY);
    const workspace = this.workspaceName;
    const hasPassword = this.getValue(PASSWORD_SET) == 'true';
    const isSso = this.getValue(IS_SSO) == 'true';
    return apiClient
      .logout({workspace}).then(({logoutUrl}) => {
        this.jwtToken = undefined;
        this.workspaceName = undefined;
        this.logout_url = logoutUrl;
      })
      .then((data) => {
        return this._syncToken();
      })
      .finally(() => {
        this.removeKey(TOKEN_IDENTIFIER);
        this.removeKey(UPDATED_AT_IDENTIFIER);
        this.removeKey(WORKSPACE);
        if(isSso){
          this.removeKey(IS_SSO);
        }
        if (email) {
          this.removeKey(EMAIL_KEY);
          this.removeKey(PASSWORD_SET);
        }
        this._redirectToLogin(workspace, email, hasPassword, isSso);
      });
  }

  getRunTimeIdQuery() {
    if (window.location.search.startsWith('?extId=')) {
      return window.location.search.replace('?extId=', '');
    }
    return undefined;
  }

  getWalkthroughRuntimeId() {
    const runTimeIdElement = document.getElementById('extension_id');
    if (runTimeIdElement && runTimeIdElement.hasAttribute('runtime_id')) {
      return runTimeIdElement.getAttribute('runtime_id') || undefined;
    }
  }

  getInstaRuntimeId() {
    const runTimeIdElement = document.getElementById('insta_extension_id');
    if (runTimeIdElement && runTimeIdElement.hasAttribute('runtime_id')) {
      return runTimeIdElement.getAttribute('runtime_id') || undefined;
    }
  }

  getWalkthroughRuntimeIdIfInstalled() {
    return Promise.resolve().then(() => {
      const runTimeIdElement = document.getElementById('extension_id');
      if (runTimeIdElement && runTimeIdElement.hasAttribute('runtime_id')) {
        return runTimeIdElement.getAttribute('runtime_id') || undefined;
      } else if (!!WALKTHROUGH_ID) {
        return this.checkIfInstalled(WALKTHROUGH_ID).then(installed => {
          return installed ? WALKTHROUGH_ID : undefined;
        });
      } else {
        return undefined;
      }
    });
  }

  getInstaRuntimeIdIfInstalled() {
    const runTimeIdElement = document.getElementById('insta_extension_id');
    if (runTimeIdElement && runTimeIdElement.hasAttribute('runtime_id')) {
      return runTimeIdElement.getAttribute('runtime_id') || undefined;
    } else if (!!INSTA_ID) {
      return this.checkIfInstalled(INSTA_ID).then(installed => {
        return installed ? INSTA_ID : undefined;
      });
    } else {
      return undefined;
    }
  }

  setTrialEnded(value) {
    this.trialEnded = value;
  }

  _syncToken(extIds: string[] | undefined = undefined) {
    return Promise.resolve()
      .then(() => {
        return this.getRuntimeIdsToSync(extIds);
      })
      .then(ids => {
        if (ids.length > 0) {
          return Promise.all(ids.map(id => this.sendToken(id))).then(() => {
            return;
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  private sendToken(extId) {
    return new Promise((resolve, reject) => {
      try {
        // TODO need to make sure if it works with both extensions
        chrome.runtime.sendMessage(
          extId,
          {
            message: 'set_jwt',
            token: this.jwtToken,
            workspace: this.workspaceName
          },
          response => {
            if (chrome.runtime.lastError) {
              reject();
            }
            resolve();
          }
        );
      } catch (error) {
        reject();
      }
    });
  }

  private getRuntimeIdsToSync(extIds: string[] | undefined) {
    return Promise.resolve()
      .then(() => {
        let output: string[] = [];
        if (extIds != undefined) {
          output = extIds;
        }

        if (!!this.getRunTimeIdQuery()) {
          output.push(this.getRunTimeIdQuery()!);
        }
        return output;
      })
      .then(output => {
        return Promise.all([
          this.getInstaRuntimeIdIfInstalled(),
          this.getWalkthroughRuntimeIdIfInstalled()
        ]).then(ids => {
          ids.forEach(id => {
            if (id) {
              output.push(id);
            }
          });
          return output;
        });
      })
      .then(output => {
        output = GlobalUtils.uniq(output);
        // if (ENVIRONMENT == 'production') {
        //   const allowedExtensionIds = [WALKTHROUGH_ID, INSTA_ID];
        //   return output.filter(f => {
        //     return allowedExtensionIds.indexOf(f) > -1;
        //   });
        // }
        return output;
      });
  }

  private checkIfInstalled(extensionId) {
    return new Promise((resolve, reject) => {
      try {
        // chrome.runtime.sendMessage(
        //   extensionId,
        //   { message: 'version' },
        //   reply => {
        //     if (chrome.runtime.lastError) {
        //       resolve(false);
        //     }
        //     if (reply && reply.version) {
        //       resolve(true);
        //     } else {
        //       resolve(false);
        //     }
        //   }
        // );
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  }

  protected storedWorkspaceName() {
    return this.getValue(WORKSPACE);
  }

  public redirectToLogin() {
    this._redirectToLogin(this.workspaceName);
  }

  protected _redirectToLogin(
    workspaceName,
    email: string | undefined = undefined,
    hasPassword = false,
    isSso = false
  ) {
    if (!workspaceName) {
      if (this.getValue(REPEAT_CUSTOMER)) {
        window.location.href = WorkspaceUtils.getCommonUrl() + '/login';
      } else {
        window.location.href = WorkspaceUtils.getCommonUrl() + '/sign_up';
      }
    } else {
      const forwardPath = window.location.pathname != '/logout' ? encodeURIComponent(window.location.pathname) : '';
      if (isSso) {
        if(this.logout_url){
          window.location.href = this.logout_url
        }else {
          window.location.href =
              WorkspaceUtils.getCommonUrl() +
              `/sso_login?workspace=${workspaceName}&forward=${forwardPath}`;
        }
      } else if (email != undefined) {
        window.location.href =
          WorkspaceUtils.getCommonUrl() +
          `/login?workspace=${workspaceName}&forward=${forwardPath}#${btoa(
            JSON.stringify({
              email,
              skipVerify: false,
              hasPassword
            })
          )}`;
      } else {
        window.location.href =
          WorkspaceUtils.getCommonUrl() +
          `/login?workspace=${workspaceName}&forward=${forwardPath}`;
      }
    }
  }

  protected storeValue(key, value) {
    if (this.isSafari) {
      window.sessionStorage.setItem(key, value);
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  protected removeKey(key) {
    if (this.isSafari) {
      window.sessionStorage.removeItem(key);
    } else {
      window.localStorage.removeItem(key);
    }
  }

  protected getValue(key) {
    if (this.isSafari) {
      return window.sessionStorage.getItem(key)!;
    } else {
      return window.localStorage.getItem(key)!;
    }
  }

  private authEventListener(e: MessageEvent) {
    if (e.origin == "https://app.hubspot.com" && typeof e.data === 'string') {
      const eventData = JSON.parse(e.data);
      if (eventData.type == 'open-change' && !eventData.data.isOpen) {
        EventBus.$emit('hubspot.widget_closed', true)
      }
    }
    if (
      e.origin == WorkspaceUtils.getCommonUrl() ||
      WorkspaceUtils.getSuffix().test(e.origin)
    ) {
      if (e.data.event && e.data.event.startsWith('emit.')) {
        EventBus.$emit(e.data.event, e.data.data);
      }
    }
  }
}
