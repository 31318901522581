
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 't-label',
  props: {
    label: {
      type: String
    },

    name: {
      type: String,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },

    size: {
      type: String
    }
  }
})
export default class TubeLabel extends Vue {
  private required!: boolean;
  private size!: any;

  get classList() {
    return {
      't-label': true,
      't-label--required': this.required,
      [`t-label--${this.size}`]: Boolean(this.size)
    };
  }
}
