
import Vue from 'vue';
import Component from 'vue-class-component';
import TubeLabel from '@/lib/form/label/Label.vue';
import { Watch } from 'vue-property-decorator';

@Component({
  name: 't-input',
  components: {
    TubeLabel
  },
  model: {
    prop: 'value',
    event: 'update'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
      validator: value => {
        return [
          'number',
          'password',
          'text',
          'hidden',
          'search',
          'range',
          'file',
          'email',
          'color',
          'time',
          'tel'
        ].includes(value);
      }
    },
    talign: {
      type: String,
      default: 'left',
      validator: value => {
        return ['left', 'center', 'right'].includes(value);
      }
    },
    size: {
      type: String,
      validator: value => {
        return ['xsmall', 'small', 'large', 'xlarge', 'bold'].includes(value);
      }
    },
    variant: {
      type: String,
      validator: value => {
        // The value must match one of these strings
        return ['text', 'range', 'bg', 'line'].includes(value);
      }
    },
    maxLength: {
      type: Number
    },
    fluid: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    min: {
      type: [String, Number]
    },
    max: {
      type: [String, Number]
    },
    step: {
      type: Number
    },
    invalid: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    invalidMessage: {
      type: String,
      default: ''
    },
    accept: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Object
    }
  }
})
export default class TubeInput extends Vue {
  disabled!: boolean;
  required!: boolean;
  readonly!: boolean;
  fluid!: boolean;
  invalid!: boolean;
  type!: string;
  focused = false;
  name!: string;
  value!: string;
  size!: string;
  talign!: string;
  min!: number;
  max!: number;
  focus!: boolean;
  variant!: string;
  invalidMessage!: string;
  loading!: boolean;
  rules!: any;
  private internalValue: string | number | undefined = '';

  created() {
    if (this.$parent.$props && this.$parent.$props.formName && this.form) {
      this.form.register(this.name, this.internalValue, this.rules);
    }
  }

  destroyed() {
    this.form && this.form.unregister(this.name);
  }

  get form() {
    return (
      this.$form &&
      this.$form[this.$parent.$props && this.$parent.$props.formName]
    );
  }

  get classList() {
    return {
      't-input': true,
      't-input--disabled': this.disabled,
      't-input--empty': !this.internalValue,
      't-input--readonly': this.readonly,
      't-input--fluid': this.fluid,
      [`t-input--${this.size}`]: Boolean(this.size),
      [`t-input--${this.talign}`]: Boolean(this.talign),
      [`t-input--${this.type}`]: ['color'].indexOf(this.type) != -1,
      [`t-input--${this.variant}`]: Boolean(this.variant),
      't-input--focused': this.focused && !(this.readonly || this.disabled),
      't-input--invalid': !!this.invalidMessage || this.invalid,
      't-input--loader': this.loading && this.type != 'search'
    };
  }

  get search() {
    return this.type == 'search';
  }

  @Watch('value')
  updateInternalValue(newValue) {
    this.internalValue = newValue;
  }

  mounted() {
    this.internalValue = this.value;
    this.form && this.form.update(this.name, this.internalValue, this.rules);
    if (this.focus) {
      window.setTimeout(() => {
        const text = this.$refs.text as HTMLInputElement;
        text && text.focus();
      }, 500);
    }
  }

  onFocus() {
    this.focused = true;
  }

  onBlur(event) {
    this.updateValue(event);
    this.focused = false;
  }

  uploadFile(event) {
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    if (files && files[0]) {
      this.internalValue = event.target.value;
      this.$emit('upload', files[0], this.type);
    }
  }

  onChange(event) {
    if (this.type === 'file') {
      this.uploadFile(event);
      return;
    } else if (this.type === 'color') {
      this.$emit('change', event.target && event.target.value);
      return;
    }
    this.updateValue(event);
  }

  onInput(event) {
    this.internalValue = event.target.value;
    this.updateValue(event);
  }

  updateValue(event) {
    this.$emit('update', event.target && event.target.value, this.name);
    this.form &&
      this.form.update(
        this.name,
        event.target && event.target.value,
        this.rules
      );
  }
}
