var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.tooltip
    ? _c(
        "button",
        _vm._g(
          _vm._b(
            {
              class: _vm.classList,
              attrs: { type: "button", disabled: _vm.disabled },
            },
            "button",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.loading
            ? _c("div", { staticClass: "t-button__loader" }, [
                _c("i", { staticClass: "fas fa-circle-notch fa-fw fa-spin" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      )
    : _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                content: _vm.tooltip,
                offset: 8,
              },
              expression: "{\n    content: tooltip,\n    offset: 8\n  }",
            },
          ],
          class: [_vm.classList, "t-disabled-opacity", "t-cursor--not-allow"],
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }