import GlobalUtils from '@/lib/utils/global-utils';

export const debounce = () => {
  let h: number;
  return (func: () => void, wait = 50) => {
    window.clearTimeout(h);
    h = window.setTimeout(() => func(), wait);
  };
};

export const clone = object => {
  if (typeof object === 'undefined') {
    return {};
  }
  return JSON.parse(JSON.stringify(object));
};

export const scriptInjector = ({
  src,
  attach = true,
  body = true
}): HTMLScriptElement => {
  if (!src) {
    GlobalUtils.assert(src == null, 'should not happen');
  }
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  if (attach) {
    body
      ? document.body.appendChild(script)
      : document.head.appendChild(script);
  }
  return script;
};
