
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    isList: {
      type: Boolean,
      default: false
    },
    animate: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    }
  },
  name: 'slide-transition'
})
export default class SlideTransition extends Vue {
  elHeight = 0;
  timing = 0;
  constructor() {
    super();
  }

  beforeEnter(el) {
    el.style.opacity = 0;
  }
  // ------------------------------------------------------------------------------
  //  Animate div from 0px to its height
  // ------------------------------------------------------------------------------
  enter(el) {
    const elHeightTemp = el.clientHeight;
    this.elHeight = elHeightTemp;
    this.timing = ((elHeightTemp / 10) * 1) / 100;
    el.style['height'] = '0';
    el.style.transition =
      'opacity ' + this.timing + 's, height ' + this.timing + 's ';
    window.setTimeout(() => {
      el.style['height'] = elHeightTemp + 'px';
    });
  }
  // ------------------------------------------------------------------------------
  //  Reset values
  // ------------------------------------------------------------------------------
  afterEnter(el) {
    el.style.opacity = 1;
    window.setTimeout(() => {
      el.style.height = 'auto';
    }, 400);
  }
  beforeLeave(el) {
    el.style.opacity = 0;
    const h = el.clientHeight;
    this.timing = ((h / 10) * 1) / 100;
    el.style.transition =
      'opacity ' +
      this.timing +
      's, height ' +
      this.timing +
      's ' +
      this.timing / 2 +
      's';
  }
  leave(el) {
    el.style.height = el.clientHeight + 'px';
    window.setTimeout(() => {
      el.style.height = '0';
    });
  }
  // ------------------------------------------------------------------------------
  //  Reset values
  // ------------------------------------------------------------------------------
  afterLeave(el) {
    window.setTimeout(() => {
      el.style.height = 'auto';
      el.style.opacity = 0;
    });
  }
}
