
import Vue from 'vue';
import Component from 'vue-class-component';
import { AuthService } from '@/app/services/auth-service';
import TButton from '@/lib/form/button/Button.vue';
import TInput from '@/lib/form/input/Input.vue';
import apiClient from '../../clients/api-client';
import TMessage from '@/lib/components/message/Message.vue';
import Loader from '@/app/components/loaders/Loader.vue';
import CreateWalkthroughModal from '@/app/components/modals/CreateWalkthroughModal.vue';
import CreateInstantModal from '@/app/components/modals/CreateInstantModal.vue';
import CreateDesktopMobileWalkthroughModal from '@/app/components/modals/CreateDesktopMobileWalkthroughModal.vue';
import CreatePresentationModal from '@/app/components/modals/CreatePresentationModal.vue';
import { EditorFlow } from '@/lib/utils/editor-type';
declare let PARTNER_MODE: boolean;
declare let PARTNER_ID: string;

@Component({
  name: 'create-video-modal',
  components: {
    TButton,
    TInput,
    TMessage,
    Loader,
    CreateWalkthroughModal,
    CreateInstantModal,
    CreateDesktopMobileWalkthroughModal,
    CreatePresentationModal,
  },
  props: ['type'],
})
export default class CreateVideoModal extends Vue {
  private invalidMsg = '';
  private invalidTitle = '';
  private videoType = '';
  private file = '';
  private fileInvalid = false;
  private createLoader = false;
  private loaded = false;
  private title = '';
  private partnerMode = PARTNER_MODE;
  private partnerId = PARTNER_ID;

  beforeOpen(e) {
    this.videoType = (e.params && e.params.type) || '';
  }

  modalOpened() {
    this.$user
      .loadWorkspaceInfo()
      .then(() => {
        this.loaded = true;
      })
      .catch((e) => {
        this.loaded = true;
        this.$error(e);
      });
  }

  get instaEnabled() {
    if (!this.$user.workspace) {
      return;
    }
    return (
      (this.$user.workspace.configs &&
        this.$user.workspace.configs.instaEnabled) ||
      (this.$user.eligible('instant_videos') &&
        this.$user.eligible('walk_through_videos'))
    );
  }

  get canPerformWalkthrough() {
    return this.$user.eligible('walk_through_videos');
  }

  get workspace() {
    return this.$user.workspace;
  }

  get canShowTrial() {
    return this.workspace && !this.workspace.trialEnd;
  }

  get editorFlowConfig() {
    return this?.workspace?.configs?.v2EditorFlow;
  }

  get isNewEditorEnabled() {
    const editorTypeArray = Object.keys(EditorFlow).map(
      (key) => EditorFlow[key]
    );

    return (
      editorTypeArray.includes(this.editorFlowConfig) &&
      this.editorFlowConfig !== EditorFlow.DISABLED
    );
  }

  openPricing() {
    this.$modal.hide('create-video');
    this.$nextTick(() => {
      this.$router.push({
        name: 'pricing_page',
      });
    });
  }

  get canShowManualUploadOption() {
    return (
      (this.$user.workspace &&
        this.$user.workspace.configs &&
        this.$user.workspace.configs.manualUploadEnabled) ||
      this.$user.eligible('video_uploads')
    );
  }

  setVideoType(type) {
    this.videoType = type;
  }

  goBack() {
    this.videoType = '';
  }

  goTo(url) {
    this.$router.push(url);
  }

  resetData() {
    this.videoType = '';
  }
}
