export default class TubeError {
  errorCode: string;
  message: string;
  paramName?: string;
  statusCode?: number;
  constructor(response, statusCode?) {
    this.errorCode = response.code;
    this.message = response.message;
    this.paramName = response.param_name;
    this.statusCode = statusCode;
  }
}
