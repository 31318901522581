
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 't-button',
  props: {
    tooltip: {},
    disabled: {
      type: Boolean,
      default: false
    },

    size: {},

    variant: {
      type: String,
      default: 'standard',
      validator(value) {
        // The value must match one of these strings
        return [
          'primary',
          'primary-ghost',
          'secondary',
          'danger',
          'danger-hard',
          'success',
          'action',
          'standard',
          'subtle',
          'icon',
          'edit-primary'
        ].includes(value);
      }
    },

    loading: {
      type: Boolean,
      default: false
    },

    vertical: {
      type: Boolean,
      default: false
    },

    fluid: {
      type: Boolean,
      default: false
    },

    animate: {
      type: Boolean,
      default: false
    }
  }
})
export default class Button extends Vue {
  variant!: string;
  size!: string;
  loading!: boolean;
  disabled!: boolean;
  fluid!: boolean;
  vertical!: boolean;
  animate!: boolean;

  get classList() {
    return {
      't-button': true,
      't-button--loading': this.loading,
      't-button--fluid': this.fluid,
      't-button--vertical': this.vertical,
      't-button--animate': this.animate,
      [`t-button--${this.variant}`]: Boolean(this.variant),
      [`t-button--${this.size}`]: Boolean(this.size)
    };
  }
}
