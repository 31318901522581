function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class CancellableTimeoutPrimise {
  timeoutId!: number;
  rejector: any;
  promise: Promise<any>;
  constructor(fun: Function, ms: number) {
    this.promise = new Promise((resolve, reject) => {
      this.timeoutId = window.setTimeout(() => {
        resolve(fun());
      }, ms);
      this.rejector = reject;
    });
  }

  cancel() {
    window.clearTimeout(this.timeoutId);
    this.rejector('auto_cancelled');
  }

  then() {
    return this.promise;
  }
}

class GlobalUtils {
  static uniq(array) {
    return array.filter(onlyUnique);
  }
  static assert(value: boolean, errorMsg: string) {
    if (!value) {
      throw new Error(errorMsg);
    }
  }

  static genUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static isValidTimeString(timeString) {
    if (!timeString || !timeString.length) {
      return false;
    }
    const dotSplits = timeString.split(".");
    const timeStringWithoutMilli = dotSplits[0];
    const millis = Number(dotSplits[1]);
    if (isNaN(millis) || millis >= 1000) {
      return false;
    }

    const timeArray = timeStringWithoutMilli.split(':').reverse();
    const seconds = Number(timeArray[0] || 0);
    const minutes = Number(timeArray[1] || 0);
    const hours = Number(timeArray[2] || 0);

    return !isNaN(minutes) && !isNaN(seconds) && !isNaN(hours);
  };

  static timeInSeconds(timeString) {
    if (this.isValidTimeString(timeString)) {
      const dotSplits = timeString.split(".")
      const timeStringWithoutMilli = dotSplits[0]
      const fraction = (Number(`0.${dotSplits[1]}` || 0))

      const timeArray = timeStringWithoutMilli.split(':').reverse();
      const seconds = Number(timeArray[0] || 0);
      const minutes = Number(timeArray[1] || 0);
      const hours = Number(timeArray[2] || 0);

      return (seconds + minutes * 60 + hours * 3600) + fraction;
    }
  }

  static convertMsToTime(ms) {
    const millis = (ms / 1000).toFixed(3).slice(0, -1).split('.')[1];
    let seconds: any = Math.floor((ms / 1000) % 60);
    let minutes: any = Math.floor((ms / (1000 * 60)) % 60);
    const hours: any = Math.floor((ms / (3600 * 1000)) % 3600);
    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return parseInt(hours)
      ? `${hours}:${minutes}:${seconds}.${millis}`
      : `0:${minutes}:${seconds}.${millis}`;
  }

  static cancellablePromise(fun: Function, ms: number) {
    return new CancellableTimeoutPrimise(fun, ms);
  }

  static promisifyTimeout(fun: Function, ms: number) {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        resolve(fun());
      }, ms);
    });
  }

  static binarySearch<T>(item: T, searchArray: Array<T>): T | undefined {
    let startIndex = 0,
      endIndex = searchArray.length - 1,
      midPoint,
      midVal,
      searchResult;
    if (item < searchArray[startIndex]) {
      return undefined;
    }
    if (item >= searchArray[endIndex]) {
      return searchArray[searchArray.length - 1];
    }

    const searchAroundPoint = (midPoint: number, item: T) => {
      if (
        item == searchArray[midPoint] ||
        (midPoint < searchArray.length - 1 &&
          item > searchArray[midPoint] &&
          item < searchArray[midPoint + 1])
      )
        return midPoint;
      else if (
        midPoint - 1 >= 0 &&
        item >= searchArray[midPoint - 1] &&
        item < searchArray[midPoint]
      )
        return midPoint - 1;

      return undefined;
    };

    while (startIndex < endIndex) {
      midPoint = Math.floor((endIndex + startIndex) / 2);
      searchResult = searchAroundPoint(midPoint, item);
      if (searchResult == undefined) {
        if (item < searchArray[midPoint]) {
          endIndex = midPoint - 1;
        } else {
          startIndex = midPoint + 1;
        }
      } else {
        return searchArray[searchResult];
      }
    }
    return undefined;
  }

  static b64toBufferArray = (b64Data, contentType = '', sliceSize = 512) => {
    const binaryString = atob(b64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  static fileToBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

export default GlobalUtils;
