var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    { class: _vm.classList, attrs: { for: _vm.name } },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n  "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }