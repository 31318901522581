
import Vue from 'vue';
import Component from 'vue-class-component';
import { MessageType } from '@/lib/components/message/types';

@Component({
  name: 't-message',
  props: {
    message: {
      default: ''
    },
    type: {
      default: ''
    },
    size: {
      default: ''
    },
    icon: {
      default: true
    }
  }
})
export default class Message extends Vue {
  private type!: MessageType;

  get styles() {
    switch (this.type) {
      case MessageType.Danger:
        return 'danger';
      case MessageType.Success:
        return 'success';
      case MessageType.Info:
        return 'info';
    }
  }
}
