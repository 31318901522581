var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "t-create__content" }, [
    _c("div", { staticClass: "t-create__main t-create__bg" }, [
      _c("div", { staticClass: "t-create__steps" }, [
        _c("div", { staticClass: "t-create__step" }, [
          _vm.partnerMode
            ? _c("img", {
                attrs: {
                  src: require(`@/lib/assets/images/create-video/${_vm.partnerId}/browser-icon.svg`),
                  height: "120",
                },
              })
            : _c("img", {
                attrs: {
                  src: require("@/lib/assets/images/create-video/instant-extension.svg"),
                  height: "120",
                },
              }),
          _vm._v(" "),
          _c("div", { staticClass: "t-create__grow t-mar--l-md" }, [
            _c("div", { staticClass: "t-fw--bolder" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("home.create_video.instant.step1.title")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "t-tc--muted" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("home.create_video.instant.step1.desc")) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "t-create__step" }, [
          _c("div", { staticClass: "t-create__grow t-mar--r-md" }, [
            _c("div", { staticClass: "t-fw--bolder" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("home.create_video.instant.step2.title")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "t-tc--muted" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("home.create_video.instant.step2.desc")) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: require("@/lib/assets/images/create-video/browser-instant.svg"),
              height: "120",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "t-create__divider t-create__step" }),
        _vm._v(" "),
        _c("div", { staticClass: "t-create__step t-flex--dc t-ta--center" }, [
          _c(
            "a",
            {
              staticClass:
                "t-create__button t-button t-button--small t-button--standard",
              attrs: {
                href: _vm.partnerMode
                  ? "https://img.trainn.co/self-carestack/trainn-insta-extension-v1.2.1.zip"
                  : "https://chrome.google.com/webstore/detail/trainn-instant/dcgncfpnhaoojbkdoicbihlhhgajocii",
                target: "_blank",
              },
            },
            [
              !_vm.partnerMode
                ? _c("img", {
                    attrs: {
                      src: require("@/lib/assets/images/Trainn-Logo-Assets/SVG/Trainn-logo-red.svg"),
                      height: "20",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "t-mar--l-mi" }, [
                _vm._v(_vm._s(_vm.$t("home.create_video.install_extension"))),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "t-fs--caption t-mar--b-no t-mar--t-xs t-tc--muted t-fw--normal",
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("home.create_video.not_installed")) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "t-create__aside" }, [
      _c("p", { staticClass: "t-mar--r-xl" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("home.create_video.instant.desc_long.1")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "t-mar--t-mi t-mar--r-xl" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("home.create_video.instant.desc_long.2")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "t-mar--t-sm t-mar--r-xl" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("home.create_video.watch_video")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "t-mar--t-lg" }, [
        _c("a", { attrs: { href: _vm.videoTutorialUrl, target: "_blank" } }, [
          _c("img", {
            attrs: {
              src: require("@/lib/assets/images/video-thumbnails/instant.svg"),
              height: "200px",
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }