
import Vue from 'vue';
import Component from 'vue-class-component';
import TButton from '@/lib/form/button/Button.vue';
import TInput from '@/lib/form/input/Input.vue';
import apiClient from '@/app/clients/api-client';

@Component({
  name: 'create-desktop-walkthrough-modal',
  props: ['type'],
  components: {
    TButton,
    TInput,
  },
})
export default class CreateDesktopWalkthroughModal extends Vue {
  private title = '';
  private invalidTitle = '';
  private file = '';
  private fileInvalid = false;
  private createLoader = false;
  private invalidFileSize = '';
  type!: any;

  get attachmentTypes() {
    return ['video/mp4', 'video/quicktime'];
  }

  get engagementToken() {
    return this.$user && this.$user.state.engagementToken;
  }

  get queryParams() {
    return this.engagementToken ? `?et=${this.engagementToken}` : '';
  }

  get videoTutorialUrl() {
    return this.type == 'desktop_recording'
      ? `https://wiki.portal.trainn.co/share/yK5JDyeVQE5qH8a88XMm1Q${this.queryParams}`
      : `https://wiki.portal.trainn.co/share/283606bd-3d77-4fd4-b173-b85a7ce3c6f8${this.queryParams}`;
  }

  uploadFile(file) {
    const size = file.size / 1024 / 1024;
    if (size > this.getMaxSize) {
      this.invalidFileSize = this.$t('home.create_video.invalid_size') + '';
      return;
    }
    this.file = file;
  }

  get workspace() {
    return this.$user.workspace;
  }

  get getMaxSize() {
    if (!this.workspace) {
      return;
    }
    return this.workspace.configs && this.workspace.configs.maxUploadSize;
  }

  createVideo() {
    this.invalidTitle = '';
    if (!this.title) {
      this.invalidTitle = this.$t('home.create_video.invalid_input') + '';
      return;
    }
    this.fileInvalid = false;
    if (!this.file) {
      this.fileInvalid = true;
      return;
    }
    this.createLoader = true;
    apiClient
      .createVideo(
        this.title,
        this.file,
        'presentation',
        this.$route.params.folderId
      )
      .then(({ id }) => {
        this.$modal.hide('create-video');
        this.$router.push({
          name: 'edit_video',
          params: {
            videoId: id,
          },
        });
        this.createLoader = false;
      })
      .catch((e) => {
        this.createLoader = false;
        this.$error(e);
      });
  }
}
