const PRODUCTION = process.env.NODE_ENV === 'production';

export default function(func: any) {
  return () =>
    func.call().catch((err: any) => {
      if (PRODUCTION) {
      } else {
        console.error(err);
      }
    });
}
