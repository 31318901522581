import UserController from './user';
import EditorController from '@/app/controllers/editor-controller';
import EditorV2Controller from '@/app/controllers/editor-v2-controller';
import GuideEditorController from '@/app/controllers/guide-editor-controller';

declare module 'vue/types/vue' {
  interface Vue {
    $setController(controller: EditorController | EditorV2Controller);
    $setUserController(controller: UserController);
    $setGuideController(controller: GuideEditorController);
    $c: EditorController | EditorV2Controller;
    $gc: GuideEditorController;
    $user: UserController;
  }
}

export const ControllerPlugin = {
  install(Vue: any) {
    Vue.prototype.$setController = function (controller) {
      Vue.prototype.$c = controller;
    };

    Vue.prototype.$setGuideController = function (controller) {
      Vue.prototype.$gc = controller;
    };

    Vue.prototype.$setUserController = function (controller) {
      Vue.prototype.$user = controller;
    };
  },
};
