var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "modal",
    {
      attrs: {
        name: "create-folder",
        width: "360",
        adaptive: "",
        classes: "t-modal--sleek",
        height: "auto",
        "pivot-y": _vm.$isMobile ? 1 : 0.5,
      },
      on: { "before-close": _vm.beforeClose },
    },
    [
      _c(
        "div",
        { staticClass: "t-pad--t-sm t-pad--b-sm t-pad--l-sm t-pad--r-sm" },
        [
          _c(
            "div",
            { staticClass: "t-fs--subtitle t-tf--bolder t-mar--b-ti" },
            [_vm._v("Folder Name")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "t-mar--t-sm" },
            [
              _c("t-input", {
                attrs: {
                  fluid: "",
                  name: "folder_name",
                  focus: true,
                  invalid: _vm.invalidInput,
                },
                on: {
                  change: function ($event) {
                    _vm.invalidInput = false
                  },
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return $event.target.blur()
                  },
                  blur: function ($event) {
                    return _vm.createFolder($event)
                  },
                  focus: function ($event) {
                    return $event.target.select()
                  },
                },
                model: {
                  value: _vm.folderName,
                  callback: function ($$v) {
                    _vm.folderName = $$v
                  },
                  expression: "folderName",
                },
              }),
              _vm._v(" "),
              _c(
                "t-button",
                {
                  staticClass: "t-mar--t-st",
                  attrs: {
                    variant: "primary",
                    loading: _vm.loading,
                    size: "small",
                    fluid: "",
                  },
                },
                [
                  _c("i", { staticClass: "mdi mdi-check-circle mdi-18px" }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("generic.button.save_changes"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }