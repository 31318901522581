export default {
  login: {
    new_user: {
      text: 'New to Trainn?',
      link: 'Sign up for free'
    },
    title: 'Good to have you back',
    // description:
    //   'Sign in to accelerate user adoption by creating professional product training videos in minutes.',
    description:
      'Trying to figure out how Trainn can help you? Don’t be a stranger, <a href="mailto:support@trainn.co">talk to us</a>',
    partner_description: 'Sign in to accelerate training at scale',
    google: 'Sign in with Google',
    login_with_email: 'Login',
    continue_with_email: 'Log in using email',
    forgot_password: 'Forgot password?',
    terms: 'By logging in you agree to our terms and conditions',
    workspace_domain: 'Workspace Domain',
    login_with_sso: 'Login',
    go_to_login_with_sso: 'Login with Single Sign-On',
    go_to_login: 'Go to Login Page'
  },
  register: {
    existing_user: {
      text: 'Have a Trainn account already?',
      link: 'Log In'
    },
    user_exists:
      'You already have an account associated with this email id. Kindly login to continue.',
    title: 'Create your account',
    change: 'Change',
    description: {agree:'I agree to Trainn’s' , terms: 'Terms' , and : 'and', privacy: 'Privacy Policy'},
    google: 'Sign up with Google',
    email_sign_up: {
      email: 'Your work email',
      email_placeholder: 'john@yoda.io',
      password: 'Password',
      confirm_password: 'Re-enter password',
      otp: 'One time password',
      otp_placeholder: 'Enter 6-digit code',
      otp_desc: 'OTP is valid for 5 minutes',
      send_otp: 'Send OTP',
      continue_with_email: 'Agree & Continue',
      otp_undelivered: 'Haven’t received the code yet?',
      resend_otp: 'Send again',
      otp_sent: 'OTP has been sent to your mail',
      reset_password: 'Your password has been reset. Kindly login to continue!',
      verify_otp: 'Verify and Proceed',
      set_password: 'Set Password',
      password_title: 'Setup your password',
      create_account: 'Create account',
      user_exists: 'User already exists. Please try logging in.',
      reset_password_desc:
        'One time password will be sent to the given email address',
      reset_password_title: 'Reset password',
      set_password_title: 'Set your account password',
      verify_otp_desc: 'Verify your account',
      verify_otp_cta_reset: 'Verify & Reset password',
      verify_otp_cta_set: 'Verify & Set password',
      verify_otp_cta_signup: 'Verify Account',
      set_password_cta_reset: 'Reset & Login',
      set_password_cta_set: 'Update & Login',
      set_password_cta_signup: 'Set up your account',
      reset_password_veriy_otp_desc:
        'We have sent an one time password(OTP) to your email address. Please verify it to reset your password.',
      set_password_veriy_otp_desc:
        'We have sent an one time password(OTP) to your email address. Please verify it to set your password.',
      set_password_veriy_otp_signup_desc:
        'We have sent a 6-digit confirmation code to your inbox. Please enter it below to verify your account.',
      passowrd_description:
        // 'Your password should have atleast one upper case character, one lower case character, one special character, one number and should have minimum 8 characters'
        'Please ensure your password is of at least 8 characters with one uppercase, one number, and one special character.'
    },
    terms: 'By signing up you agree to our terms and conditions',
    profile: {
      title: 'Time to create your profile!',
      description: 'Help us craft the best experience just for you.',
      name: 'Your Name',
      placeholder: 'Chewbacca',
      cta: 'Set & Continue',
    },
    phone: {
      name: 'Your Phone Number',
      placeholder: '+1 (123) 456-7890',
    },
    workspace: {
      title: "Let's set up your workspace",
      join: 'Join your teammates workspace',
      description: 'For a more personalised experience',
      input: {
        label: 'Workspace name',
        display_label: 'Workspace display name',
        placeholder: 'Enter workspace name',
        error: 'Please enter a product space name',
        min_length_error: 'Workspace name should have a minimum of 4 characters'
      },
      domain: {
        label: 'Domain name',
        placeholder: 'Enter domain name',
        description: `We will generate %{domain}.trainn.co as your workspace URL`,
        join: `Allow anyone with an @%{domain} domain to join this workspace`,
        error: 'Please enter a valid domain name',
        already_exists: 'Given domain name already exists',
        validity:
          'Your domain name should be atleast 4 characters and should not contain any special characters'
      }
    },
    invite_team: {
      title: 'The more the merrier',
      description: `Let’s invite your team to <b>%{domain}</b>. They will be given Creator access by default.`,
      cta: 'Send Invites & Continue',
      input: {
        placeholder: 'Separate emails with space, comma or enter'
      },
      skip: 'You can skip and',
      later: 'do this later'
    },
    extension: {
      title: 'Install Extension',
      description:
        'Almost done! Download our chrome extension and record your first workflow right away!',
      btn: 'Install Extension',
      skip: 'I will install later'
    },
    btn: {
      proceed: 'Proceed',
      update: 'Update',
      quick_start_guide: 'Quick start guide',
      create_new_workspace: 'Create New Workspace'
    }
  },
  signupTestimonial: {
    testimonial_1: {
      title:
        'No Fuss Platform So You Can Focus On The Quality Of Your Customer Training',
      author: '— <b>Chirag P</b> | WebEngage',
      role: 'AVP - Marketing (L&D)'
    },
    testimonial_2: {
      title:
        'We really enjoyed the experience of building the Wingman academy…',
      author: '— <b>Vinay Prasad</b> | Wingman by Clari',
      role: 'Senior Customer Success Manager'
    },
    testimonial_3: {
      title:
        'Amazing product with 10X more features than Loom, Camtasia, and Vidyard.',
      author: '— <b>Pavan Mallikarjun</b> | Lief Care',
      role: 'Chief Executive Officer'
    },
    logos_description: 'You’re in good company. Try Trainn for free.'
  }
};
