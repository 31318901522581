
import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '@/lib/utils/event-bus';

@Component({
  name: 'show-video-modal',
  components: {}
})
export default class ShowVideoModal extends Vue {
  private videoTutorial = '';

  get engagementToken() {
    return this.$user && this.$user.state.engagementToken;
  }

  get queryParams() {
    return this.engagementToken ? `?et=${this.engagementToken}` : '';
  }

  created() {
    EventBus.$on('video_tutorial_url', this.setUrl);
  }

  setUrl(url) {
    this.videoTutorial = url + this.queryParams;
  }

  destroyed() {
    EventBus.$off('video_tutorial_url', this.setUrl);
  }
}
