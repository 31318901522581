var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "t-create__content" }, [
    _c("div", { staticClass: "t-create__main t-create__bg" }, [
      _c("img", {
        attrs: {
          src: require("@/lib/assets/images/icons/animation-play.svg"),
          height: "48",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "t-fw--bolder t-mar--t-xs" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t(`home.create_video.${_vm.type}.title`)) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "t-create__form t-mar--t-st" },
        [
          _c("t-input", {
            attrs: {
              size: "small",
              fluid: "",
              label: _vm.$t("home.create_video.input_label"),
              placeholder: _vm.$t("home.create_video.input_placeholder"),
              "invalid-message": _vm.invalidTitle,
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.createVideo()
              },
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _vm._v(" "),
          _c("t-input", {
            staticClass: "t-mar--t-sm",
            attrs: {
              type: "file",
              fluid: "",
              size: "small",
              accept: _vm.attachmentTypes,
              label: _vm.$t(`home.create_video.${_vm.type}.choose_file`),
              invalid: _vm.fileInvalid,
              "invalid-message": _vm.invalidFileSize,
            },
            on: { upload: _vm.uploadFile },
          }),
          _vm._v(" "),
          _c(
            "t-button",
            {
              staticClass: "t-mar--t-lg",
              attrs: {
                variant: "primary",
                size: "medium",
                fluid: "",
                loading: _vm.createLoader,
              },
              on: {
                click: function ($event) {
                  return _vm.createVideo()
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("home.create_video.create")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "t-create__aside" }, [
      _c("p", {
        staticClass: "t-mar--r-xl t-mar--t-no",
        domProps: {
          innerHTML: _vm._s(_vm.$t(`home.create_video.${_vm.type}.desc_long`)),
        },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "t-mar--t-sm t-mar--r-xl" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("home.create_video.watch_video")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "t-mar--t-lg" }, [
        _c("a", { attrs: { href: _vm.videoTutorialUrl, target: "_blank" } }, [
          _vm.type == "images"
            ? _c("img", {
                attrs: {
                  src: require("@/lib/assets/images/video-thumbnails/images-walkthrough.svg"),
                  height: "200px",
                },
              })
            : _c("img", {
                attrs: {
                  src: require("@/lib/assets/images/video-thumbnails/pitch.svg"),
                  height: "200px",
                },
              }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }