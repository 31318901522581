import ToastComponent from './ToastComponent.vue';
import Toast from './toast';

const ToastPlugin = {
  install(Vue: any) {
    const component = Vue.component(ToastComponent.name, ToastComponent);
    Vue.$toast = Vue.prototype.$toast = new Toast(new component());

    Vue.prototype.$show = (params: any) => {
      Vue.prototype.$toast.add(params);
    };

    Vue.prototype.$remove = (id: string) => {
      Vue.prototype.$toast.remove(id);
    };
  }
};

export default ToastPlugin;
