declare let ENVIRONMENT: string;
declare let APP_DOMAIN: string;
declare let API_DOMAIN: string;
declare let INTERCOM_CLIENT_ID: string;
declare let PARTNER_MODE;
declare let PARTNER_ID;

export default class WorkspaceUtils {
  static sslEnabled() {
    // return true;
    return ENVIRONMENT != 'development';
  }

  static protocolPrefix() {
    return this.sslEnabled() ? 'https' : 'http';
  }

  static getApiDomain() {
    return API_DOMAIN;
  }

  static getAppDomain() {
    return APP_DOMAIN;
  }

  static workspaceApiUrl(workspace) {
    // assert workspace is not undefined
    return `${this.protocolPrefix()}://${workspace}.api.${this.getApiDomain()}`;
  }

  static workspaceEdgeApiUrl(workspace) {
    // assert workspace is not undefined
    return `${this.protocolPrefix()}://${workspace}.edgeapi.${this.getApiDomain()}`;
  }

  static parseWorkspaceName(hostname) {
    const firstParam = hostname.split('.')[0];
    return firstParam != 'app' && PARTNER_MODE
      ? `${firstParam}-${PARTNER_ID}`
      : firstParam;
  }

  static commonApiUrl() {
    // return 'https://3691-27-5-101-198.ngrok.io';
    return `${this.protocolPrefix()}://api.${this.getApiDomain()}`;
  }

  static getWorkspaceUrl(
    workspace,
    forwardPath: string | undefined = undefined
  ) {
    const refinedWorkspace = PARTNER_MODE
      ? workspace.replace(new RegExp(`-${PARTNER_ID}$`), '')
      : workspace;
    // let output = `https://${refinedWorkspace}.${this.getAppDomain()}`;
    let output = `${this.protocolPrefix()}://${refinedWorkspace}.${this.getAppDomain()}`;
    if (!!forwardPath) {
      output += forwardPath;
    }
    return output;
  }

  static getCommonUrl(forwardPath: string | undefined = undefined) {
    let output = `${this.protocolPrefix()}://app.${this.getAppDomain()}`;
    if (!!forwardPath) {
      output += forwardPath;
    }
    return output;
  }

  static getSuffix() {
    return new RegExp(
      `${this.protocolPrefix()}:\\/\\/(.*).${this.getAppDomain()}`
    );
  }

  static dataPassing(data) {
    return btoa(JSON.stringify(data));
  }

  static getIntercomClientId() {
    return INTERCOM_CLIENT_ID;
  }

  static getIntercomConnectUrl(state) {
    return `https://app.intercom.com/oauth?client_id=${this.getIntercomClientId()}&state=${state}`;
  }
}
