import Form from './form';

declare module 'vue/types/vue' {
  interface Vue {
    $initForm(form: Form);
    $destroyForm(name: string);
    $form: Form;
  }
}

export const FormPlugin = {
  install(Vue: any) {
    Vue.prototype.$initForm = form => {
      if (!Vue.prototype.$form) {
        Vue.prototype.$form = {};
      }
      Vue.prototype.$form[form.name] = form;
    };
    Vue.prototype.$destroyForm = name => {
      if (!Vue.prototype.$form || !Vue.prototype.$form[name]) {
        return;
      }
      delete Vue.prototype.$form[name];
    };
  }
};
