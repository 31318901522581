
import CelloReferralComponent from "@/app/components/CelloReferralComponent.vue";

export enum Tabs {
  dashboard = 'dashboard',
  library = 'library',
  collections = 'collections',
  academy = 'academy',
  hub = 'hub',
  learners = 'learners',
  learnerGroup = 'learner_group',
  integrations = 'integrations',
  trash = 'trash',
  emails = "emails",
}
import Vue from 'vue';
import Component from 'vue-class-component';
import TButton from '@/lib/form/button/Button.vue';
import { Watch } from 'vue-property-decorator';
import { AuthService } from '@/app/services/auth-service';
import { EditorFlow } from '@/lib/utils/editor-type';
// import TrainnWidget from '@/lib/widget';

declare let PARTNER_MODE: boolean;
declare let PARTNER_ID: string;

@Component({
  name: 't-nav',
  components: {
    CelloReferralComponent,
    TButton,
  },
  props: ['message'],
})
export default class TrainnNav extends Vue {
  private currentTab = Tabs.dashboard;
  private tabs;
  private subTab = '';
  private logoutLoader = false;
  private expandLibrary = false;
  private showAcademySubTab = false;
  private partnerMode = PARTNER_MODE;
  private partnerId = PARTNER_ID;
  // private widget: any = TrainnWidget({ siteName: 'wiki' });
  private rootFolderId = '';

  beforeCreate() {
    this.tabs = Tabs;
  }

  created() {
    this.currentTab = this.$route.meta && this.$route.meta.tab;
    this.subTab = this.$route.meta && this.$route.meta.subTab;
    this.expandLibrary = this.currentTab == Tabs.library;
    this.showAcademySubTab = this.currentTab == Tabs.academy;

    this.$root.$on('update-root-folder-id', this.updateRootFolderId);
  }

  @Watch('$route')
  onRouteChange(to, from) {
    this.currentTab = to.meta && to.meta.tab;
    this.subTab = this.$route.meta && this.$route.meta.subTab;
    this.expandLibrary = this.currentTab == Tabs.library;
    this.showAcademySubTab = this.currentTab == Tabs.academy;
  }

  get profile() {
    return this.$user && this.$user.state.profile;
  }

  get initials() {
    if (this.$isEmpty(this.profile)) {
      return;
    }
    if (this.profile.fullName) {
      return this.profile.fullName.charAt(0);
    } else if (this.profile.email) {
      return this.profile.email.charAt(0);
    }
  }

  get onBoardingTasks() {
    return this.$user && this.$user.onBoardingTasks;
  }

  get completeCount() {
    if (this.$isEmpty(this.onBoardingTasks)) {
      return 0;
    }
    return Object.keys(this.onBoardingTasks).filter(
      (t) => this.onBoardingTasks[t].completed
    ).length;
  }

  get completionPercentage() {
    return ((this.completeCount / 6) * 100).toFixed(0) + '%';
  }

  get workspace() {
    return this.$user.workspace;
  }

  get isAcademyEnabled() {
    if (!this.workspace) {
      return;
    }
    return this.workspace.configs && this.workspace.configs.academyEnabled;
  }

  get isTrainingEventsEnabled() {
    return (
      this.workspace &&
      this.workspace.configs &&
      this.workspace.configs.trainingEventsEnabled
    );
  }

  toggleAcademy() {
    if (this.$isMobile) return;
    this.showAcademySubTab = !this.showAcademySubTab;
  }

  toggleLibrary() {
    if (this.$isMobile) return;
    this.expandLibrary = !this.expandLibrary;
  }

  manage() {
    this.$modal.show('manage-workspace');
  }

  openCreateModal() {
    if (this.canRedirectToRecordPage) {
      this.$router.push('/record');
      return;
    }
    this.$modal.show('create-video');
  }

  updateRootFolderId(id) {
    this.rootFolderId = id;
  }

  get editorFlowConfig() {
    return this?.$user?.workspace?.configs?.v2EditorFlow;
  }

  get canRedirectToRecordPage() {
    const editorTypeArray = Object.keys(EditorFlow).map(
      (key) => EditorFlow[key]
    );

    return (
      editorTypeArray.includes(this.editorFlowConfig) &&
      this.editorFlowConfig === EditorFlow.STRICTLY_ENABLED
    );
  }

  logout() {
    this.logoutLoader = true;
    AuthService.getInstance().logout();
  }

  openLearningCenter() {
    window.Trainn('configure', {
      domainName: 'wiki',
      learner: {
        email: this.$user.state.profile.email,
      },
      learnerHash: this.$user.state.learnerHash,
    });
    window.Trainn('open');
    // if (this.$user.state.engagementToken) {
    //   this.widget.setUserToken(this.$user.state.engagementToken);
    // }
    // this.widget!.show();
  }

  destroyed() {
    this.$root.$off('update-root-folder-id', this.updateRootFolderId);
  }
}
