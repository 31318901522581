
import Vue from 'vue';
import Component from 'vue-class-component';
import { scriptInjector } from '@/lib/utils/functions.ts';

@Component({
  name: 'intercom-widget',
})
export default class IntercomWidget extends Vue {
  mounted() {
    window.fwSettings = {
      widget_id: 69000001323,
    };
    if ('function' != typeof window.Intercom) {
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      window.Intercom = i;
    }
    window.intercomSettings = {
      app_id: 'c86s7msr',
      name: this.$user.profile.fullName,
      email: this.$user.profile.email,
      user_id: this.$user.profile.uuid,
    };
    scriptInjector({
      src: 'https://widget.intercom.io/widget/c86s7msr',
    });
  }
}
