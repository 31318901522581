var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "t-pad--b-st t-pad--l-st t-pad--r-st",
      class: {
        "t-pad--t-st": !_vm.isImportMode,
        "t-flex t-flex--dc t-flex--jcsb": _vm.isImportMode,
      },
      style: { height: _vm.isImportMode ? "100%" : "auto" },
    },
    [
      _c(
        "div",
        [
          _c("h4", [_vm._v("\n      " + _vm._s(_vm.uploadTitle) + "\n    ")]),
          _vm._v(" "),
          _vm.canCollectTitle
            ? _c("t-input", {
                attrs: {
                  fluid: "",
                  label: _vm.uploadInputLabel,
                  placeholder: _vm.uploadInputPlaceholder,
                  "invalid-message": _vm.invalidTitle,
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.uploadVideo()
                  },
                },
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("t-input", {
            staticClass: "t-mar--t-sm",
            attrs: {
              type: "file",
              fluid: "",
              accept: _vm.attachmentTypes,
              label: _vm.$t(`home.create_video.manual_upload.label`),
              invalid: _vm.fileInvalid,
            },
            on: { upload: _vm.uploadFile },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "t-mar--t-st t-ta--right" },
        [
          _c(
            "t-button",
            {
              attrs: {
                variant: "primary",
                fluid: "",
                loading: _vm.createLoader,
              },
              on: {
                click: function ($event) {
                  return _vm.uploadVideo()
                },
              },
            },
            [
              _vm.isImportMode
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("generic.button.add")) + " →"),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("home.create_video.manual_upload.upload"))
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }