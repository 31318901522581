var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "modal",
    {
      attrs: {
        name: "show-video-modal",
        width: "640",
        height: "auto",
        adaptive: "",
        styles: "border-radius:0 !important",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "padding-bottom": "56.25%",
            height: "0",
            "border-radius": "0",
            overflow: "hidden",
            "min-width": "320px",
          },
        },
        [
          _c("iframe", {
            staticStyle: {
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            },
            attrs: {
              src: _vm.videoTutorial,
              frameborder: "0",
              webkitallowfullscreen: "",
              mozallowfullscreen: "",
              allowfullscreen: "",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }